import { ChangeEvent, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextField, IconButton, InputAdornment } from '@mui/material';

import { useGetHomeCategories } from 'src/api/public';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { ITestsTableFilters, ITestsTableFilterValue } from 'src/types/staff/tests';

// ----------------------------------------------------------------------

type Props = {
  filters: ITestsTableFilters;
  onFilters: (name: string, value: ITestsTableFilterValue) => void;
};

export default function TestsTableToolbar({ filters, onFilters }: Props) {
  const popover = usePopover();

  const { data: categories = [] } = useGetHomeCategories();

  const handleFilter = useCallback(
    (
      event:
        | SelectChangeEvent<ITestsTableFilterValue>
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      const { value } = event.target;
      if (field === 'categoryId') {
        const selectedCategory = (categories as unknown as { id: string; name: string }[]).find(
          (cat) => cat.id === value,
        );
        onFilters('categoryId', selectedCategory?.id || '');
        onFilters('categoryName', selectedCategory?.name || '');
        //   } else if (field === 'statusValue') {
        //     if (value === 'true') {
        //       onFilters('isPublished', true);
        //     } else if (value === 'false') {
        //       onFilters('isPublished', false);
        //     } else {
        //       onFilters('isPublished', null);
        //     }
      } else {
        onFilters(field, value);
      }
    },
    [categories, onFilters],
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 287 },
          }}
        >
          <InputLabel id="category-select-label">Category</InputLabel>

          <Select
            labelId="category-select-label"
            id="category-select"
            value={filters.categoryId}
            onChange={(e) => handleFilter(e, 'categoryId')}
            input={<OutlinedInput label="Category" />}
            sx={{ textTransform: 'capitalize' }}
          >
            {(categories as unknown as { id: string; name: string }[]).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 287 },
          }}
        >
          <InputLabel id="status-select-label">Status</InputLabel>

          <Select
            labelId="status-select-label"
            id="status-select"
            value={filters.status}
            onChange={(e) => handleFilter(e, 'status')}
            input={<OutlinedInput label="Status" />}
          >
            <MenuItem value="Published">Published</MenuItem>
            <MenuItem value="Unpublished">Unpublished</MenuItem>
          </Select>
        </FormControl>

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.searchQuery}
            onChange={(e) => handleFilter(e, 'searchQuery')}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <IconButton onClick={popover.onOpen} disabled>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}
