import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { getAccountRole } from 'src/api/public';
import { USER_ROLES } from 'src/constants/user-roles';

import { SplashScreen } from 'src/components/loading-screen';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

// ----------------------------------------------------------------------

export default function NonStaffGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

function Container({ children }: Props) {
  const router = useRouter();
  const { user } = useAuthContext();

  const [loading, setLoading] = useState(false);

  const check = useCallback(async () => {
    setLoading(true);
    try {
      const accountRole: string = await getAccountRole();
      if (accountRole !== USER_ROLES.STUDENT) router.push(paths.staff.root);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [router]);

  useEffect(() => {
    if (user) check();
  }, [user, check]);

  return loading ? <SplashScreen /> : children;
}
