import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select';

import { ITestSection } from 'src/types/staff/tests';

type Props = SelectProps & {
  sectionOpts: ITestSection[];
  selectedSectionId: string;
  setSelectedSectionId: (section: string) => void;
};

export default function TestSectionsDropdown({
  sectionOpts,
  selectedSectionId,
  setSelectedSectionId,
  ...other
}: Props) {
  const handleSectionSelect = (event: SelectChangeEvent<unknown>) => {
    setSelectedSectionId(event.target.value as string);
  };

  return (
    <Select
      {...other}
      value={selectedSectionId}
      onChange={handleSectionSelect}
      placeholder="Select a section"
      displayEmpty
    >
      <MenuItem value="" sx={{ color: 'grey.900' }}>
        No section
      </MenuItem>
      {sectionOpts.map((section) => (
        <MenuItem value={section.id || section._tempId} sx={{ color: 'grey.900' }}>
          {section.name}
        </MenuItem>
      ))}
    </Select>
  );
}
