import React, { Children } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { Box, Button, Tooltip, TableRow, TableCell, Typography, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import queryKeys from 'src/constants/query-keys';
import { deleteStudentResults } from 'src/api/students';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { ITableColumns } from 'src/types/misc';

type Props = {
  row: any;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function ResultTabTableRow({ row, headLabel }: Props) {
  const confirm = useBoolean();
  const queryClient = useQueryClient();

  const handleDeleteStudentResult = async () => {
    try {
      confirm.onFalse();
      await deleteStudentResults(row.id);
      enqueueSnackbar(`Student Result Deleted`);
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.students.result] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title="Share result" placement="top" arrow>
        <IconButton>
          <Iconify icon="solar:share-bold" color="customColors.custom1" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title="Delete result" placement="top" arrow>
        <IconButton onClick={() => confirm.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.id) {
              if (headCell.id === 'testLevelName') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Typography variant="subtitle2" fontWeight={900}>
                      {row?.[headCell?.id] || ''}
                    </Typography>
                  </TableCell>
                );
              }

              if (headCell.id === 'createdAt') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      color: 'text.secondary',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {row?.[headCell?.id] || ''}
                  </TableCell>
                );
              }

              if (headCell.id === 'scorePercentage') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {`${row?.[headCell?.id]}%` || ''}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <React.Fragment key="actions">{tableActions}</React.Fragment>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {row?.[headCell?.id] || ''}
                </TableCell>
              );
            }

            return <React.Fragment key="deleteAction">{tableDeleteAction}</React.Fragment>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              Delete Student result?
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400}>
            Are you sure you want to delete this Student result?
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteStudentResult}>
            Delete
          </Button>
        }
      />
    </>
  );
}
