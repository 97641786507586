import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Button, Typography, CardContent, ListItemText } from '@mui/material';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import queryKeys from 'src/constants/query-keys';
import { updateTemplate } from 'src/api/templates';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';

import { ITemplateDetailItem } from 'src/types/templates';

type Props = {
  templateDetails: ITemplateDetailItem;
};

export default function TemplateEditForm({ templateDetails }: Props) {
  const queryClient = useQueryClient();

  const templateEditFormSchema = yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required('Name is Required'),
    content: yup.string().required('Content is Required'),
    subject: yup.string(),
  });

  const defaultValues: ITemplateDetailItem = useMemo(
    () => ({
      id: templateDetails.id || '',
      name: templateDetails.name || '',
      content: templateDetails.content || '',
      subject: templateDetails.subject || '',
    }),
    [templateDetails],
  );

  const methods = useForm({
    resolver: yupResolver(templateEditFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formatData = {
        ...templateDetails,
        name: data.name,
        content: data.content,
      };

      await updateTemplate(removeFalsyValuesFromObject(formatData));

      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff.notifications.templates.template],
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      enqueueSnackbar('Update success!');
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        reset(defaultValues);
      } else {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  });

  const handleReset = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (templateDetails) {
      handleReset();
    }
  }, [templateDetails, reset, defaultValues, handleReset]);

  const renderText = (
    <ListItemText
      primary="Details"
      secondary={templateDetails.subject || ''}
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
        fontWeight: 900,
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'body2',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );

  const action = (
    <Stack direction="row" spacing={1.5} justifyContent="end">
      <LoadingButton
        type="submit"
        variant="contained"
        color="success"
        size="medium"
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
      <Button variant="outlined" size="medium" onClick={handleReset}>
        Cancel
      </Button>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 2fr',
          },
          gap: 3,
          width: '100%',
        }}
      >
        <Stack>{renderText}</Stack>
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <RHFTextField name="name" size="medium" fullWidth placeholder="Test completed" />

                <Typography variant="subtitle2" fontWeight={900}>
                  Content
                </Typography>
                <Box>
                  <RHFEditor
                    simple
                    name="content"
                    placeholder="Write something awesome..."
                    sx={{
                      minHeight: 402,
                      '& .ql-editor': {
                        bgcolor: 'transparent',
                      },
                    }}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
          {action}
        </Stack>
      </Box>
    </FormProvider>
  );
}
