import { keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { IPremiseCreateItem } from 'src/types/premises';
import { IListFilters, IFetchBaseParams } from 'src/types/misc';

export function useGetPremisesList(params?: IListFilters<Omit<any, 'searchText'>>) {
  return useGetData({
    args: [endpoints.staff.premises, { params }],
    queryKey: [queryKeys.staff.premises.fetchAllPremises, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export function useGetPremise(params?: IFetchBaseParams) {
  return useGetData({
    args: [`${endpoints.staff.premises}/${params?.id}`],
    queryKey: [queryKeys.staff.premises.fetchPremise, params?.id],
  });
}

export async function deletePremise(id: string) {
  const URL = `${endpoints.staff.premises}/${id}`;
  await axiosInstance.delete(URL);
}

export async function createPremises(data: Partial<IPremiseCreateItem>) {
  const URL = `${endpoints.staff.premises}`;
  await axiosInstance.post(URL, data);
}

export async function updatePremise(data: Partial<IPremiseCreateItem>) {
  const URL = `${endpoints.staff.premises}/${data?.id}`;
  await axiosInstance.put(URL, data);
}
