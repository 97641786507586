import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import StudentGuard from 'src/auth/guard/student-guard';

import { SplashScreen } from 'src/components/loading-screen';

import { paths } from '../paths';

// ----------------------------------------------------------------------

// Lazily import page components here

// ----------------------------------------------------------------------

export const studentRoutes = [
  {
    path: paths.student.root,
    element: (
      <StudentGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </StudentGuard>
    ),
    children: [{ path: paths.student.results, element: <h1>Results page</h1> }],
  },
];
