import { UseQueryResult, keepPreviousData } from '@tanstack/react-query';

import { useGetData, usePostData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import keys from 'src/constants/query-keys';

import { IQuestion } from 'src/types/question';
import {
  ITestsTableFilters,
  ITestDetailsResponse,
  ICreateOrUpdateTestData,
  SaveTestQuestionsYupSchema,
} from 'src/types/staff/tests';

export function useGetTests(params?: ITestsTableFilters) {
  return useGetData({
    args: [endpoints.staff.test.tests, { params }],
    queryKey: [keys.staff.tests.fetchTests, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function archiveTest(testId: string) {
  const URL = `${endpoints.staff.test.tests}/${testId}/archive`;
  await axiosInstance.post(URL);
}

export async function createOrUpdateTest(
  data: ICreateOrUpdateTestData,
): Promise<{ data: { data: { testId: string } } }> {
  const URL = `${endpoints.staff.test.tests}`;
  return axiosInstance.post(URL, data);
}

export async function updateTestPublishStatus(testId: string, data: { status: boolean }) {
  const URL = `${endpoints.staff.test.tests}/${testId}/status`;
  await axiosInstance.put(URL, data);
}

export function useGetTestById(id: string): UseQueryResult<ITestDetailsResponse, Error> {
  return useGetData({
    args: [`${endpoints.staff.test.tests}/${id}`],
    queryKey: [keys.staff.tests.fetchTest, id],
  });
}

export function useGetQuestionsForTest(data: {
  search: string;
  excludeIds: string[] | [];
}): UseQueryResult<IQuestion[] | Error> {
  return usePostData({
    args: [endpoints.staff.test.questions, { ...data }],
    queryKey: [keys.staff.tests.fetchQuestionsForTest, { data }],
    options: {
      enabled: data.search.trim() !== '',
    },
  });
}

export async function saveTestQuestions(testId: string, data: SaveTestQuestionsYupSchema) {
  const URL = `${endpoints.staff.test.testQuestions}/${testId}`;
  await axiosInstance.post(URL, data);
}
