import { useState, ChangeEvent } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Box, Link, Switch, Typography, FormControlLabel } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import { ANSWERS_TYPES_CONFIG } from 'src/constants/questions';
import { archiveQuestion, updateQuestionLiveStatus } from 'src/api/question';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { IListFilters } from 'src/types/misc';
import { IQuestionItem, IQuestionTableFilters } from 'src/types/question';
// import { IUserItem } from 'src/types/user';

// import UserQuickEditForm from './user-quick-edit-form';

// ----------------------------------------------------------------------

type Props = {
  row: IQuestionItem;
  filters: IListFilters<IQuestionTableFilters>;
};

export default function QuestionTableRow({ row, filters }: Props) {
  const { questionType, question, isPublished, tags, totalPoints, id, createdAt } = row;

  const queryClient = useQueryClient();

  const [activeStatus, setActiveStatus] = useState(isPublished);

  const confirm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const quickEdit = useBoolean();

  const handleDeleteQuestion = async () => {
    confirm.onFalse();
    await archiveQuestion(id!);
    enqueueSnackbar(`Question Deleted`);
    queryClient.invalidateQueries({ queryKey: ['questions'] });
  };

  // const isLiveToggling = useBoolean(false);

  const handleLiveToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    const status = e.currentTarget.checked;
    try {
      // isLiveToggling.onTrue();
      await updateQuestionLiveStatus(id!, { isPublished: status });
      setActiveStatus(status);
      // checkout.refetchCart()
      // isLiveToggling.onFalse();
      queryClient.invalidateQueries({ queryKey: ['questions'] });
    } catch (error) {
      enqueueSnackbar(error?.message ? error?.message : JSON.stringify(error || '{}'), {
        variant: 'error',
      });
      // isLiveToggling.onFalse();
    }
  };

  const redirectUrl = paths.staff.test.questions.edit(id!);

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Box
            sx={{
              width: '32px',
              height: '32px',
              bgcolor: (theme) => theme.palette.primary.lighter,
              borderRadius: 3,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Iconify
              icon={ANSWERS_TYPES_CONFIG[questionType]?.iconifyIcon}
              width={20}
              color="blue"
            />
          </Box>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Link
            color="inherit"
            underline="hover"
            href={redirectUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {question}
          </Link>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {tags?.map((t) => (
            <Label
              key={t}
              variant="soft"
              color={
                // (status === 'paid' && 'success') ||
                // (status === 'pending' && 'warning') ||
                // (status === 'overdue' && 'error') ||
                'default'
              }
            >
              {t}
            </Label>
          ))}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{totalPoints}</TableCell>

        <TableCell>
          <FormControlLabel
            control={<Switch checked={activeStatus} color="success" onChange={handleLiveToggle} />}
            label="Live"
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(createdAt)}</TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              LinkComponent={RouterLink}
              href={redirectUrl}
              onClick={quickEdit.onTrue}
            >
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Duplicate" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              LinkComponent={RouterLink}
              href={`${paths.staff.test.questions.edit(id!)}?new=true`}
              onClick={quickEdit.onTrue}
            >
              <Iconify icon="ph:copy-duotone" />
            </IconButton>
          </Tooltip>

          <IconButton color="error" onClick={confirm.onTrue}>
            <Iconify icon="ic:twotone-delete" />
          </IconButton>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="ic:twotone-delete" /> Delete question?
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1">
              Are you sure you want to delete this question?
            </Typography>
            <Typography variant="body1">
              The question will be removed from all the tests.
            </Typography>
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteQuestion}>
            Delete
          </Button>
        }
      />
    </>
  );
}
