import { keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { IListFilters } from 'src/types/misc';
import { IUpdateStudentItem, IStudentsTableFilters } from 'src/types/students';

export function useGetStudentsList(
  params?: IListFilters<Omit<IStudentsTableFilters, 'searchText'>>,
) {
  return useGetData({
    args: [endpoints.staff.students, { params }],
    queryKey: [queryKeys.staff.students.students, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export function useGetStudentDetails(id: string) {
  return useGetData({
    args: [`${endpoints.staff.students}/${id}`],
    queryKey: [queryKeys.staff.students.student],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function deleteStudent(id: string) {
  const data = { isArchived: true };
  const URL = `${endpoints.staff.students}/${id}`;
  await axiosInstance.put(URL, data);
}

export async function updateStudent(data: Partial<IUpdateStudentItem>) {
  const URL = `${endpoints.staff.students}/${data?.id}`;
  await axiosInstance.put(URL, data);
}

export function useGetStudentResultList(id: string, params?: IListFilters<any>) {
  return useGetData({
    args: [endpoints.staff.student.results(id ?? ''), { params }],
    queryKey: [queryKeys.staff.students.results, params],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function deleteStudentResults(id: string) {
  const URL = `${endpoints.staff.student.results(id)}`;
  await axiosInstance.delete(URL);
}

export function useGetStudentNotificationsList(id: string) {
  return useGetData({
    args: [endpoints.staff.student.notifications(id ?? '')],
    queryKey: [queryKeys.staff.students.notifications],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}
