import { useState } from 'react';

import { Tab, Tabs, alpha, Container } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useSearchParams } from 'src/routes/hooks';

import { useGetTestById } from 'src/api/staff/tests';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import TestEditGeneralView from '../test-edit-general-view';
import TestEditResultsView from '../test-edit-results-view';
import TestEditQuestionsView from '../test-edit-questions-view';

export const TABS = [
  { name: 'General', iconifyIcon: 'material-symbols:info' },
  { name: 'Questions', iconifyIcon: 'solar:notes-bold' },
  { name: 'Results', iconifyIcon: 'solar:bill-list-bold' },
  { name: 'Share', iconifyIcon: 'solar:share-bold' },
];

export default function TestEditView({ id }: { id: string }) {
  const settings = useSettingsContext();

  const searchParam = useSearchParams();
  const isNew = searchParam.get('new') === 'true';

  const { data: test, isPending } = useGetTestById(id);

  const [currentTab, setCurrentTab] = useState<string>(TABS[isNew ? 1 : 0].name);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={test?.name}
        links={[
          { name: 'Dashboard', href: '#' },
          { href: paths.staff.test.tests.root, name: 'Tests' },
          { name: test?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Tabs
        value={currentTab}
        onChange={(e, newVal) => setCurrentTab(newVal)}
        sx={{
          borderBottom: (theme) => `2px solid ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {TABS.map((type) => (
          <Tab
            key={type.name}
            sx={{ fontWeight: 'fontWeightBold' }}
            value={type.name}
            iconPosition="start"
            icon={
              <Iconify
                icon={type.iconifyIcon}
                width={24}
                color={currentTab === type.name ? 'info.dark' : 'grey.600'}
              />
            }
            label={type.name}
          />
        ))}
      </Tabs>

      {/* General View */}
      {currentTab === 'General' && !isPending && test && <TestEditGeneralView test={test} />}

      {/* Questions View */}
      {currentTab === 'Questions' && !isPending && (
        <TestEditQuestionsView
          testId={test?.id || ''}
          testQuestions={test?.testQuestions || []}
          sections={test?.sections || []}
          isRandomized={test?.isRandomized || false}
          dimensions={test?.dimension || null}
          totalQuestions={test?.totalQuestions || 0}
        />
      )}

      {/* Results View */}
      {currentTab === 'Results' && !isPending && <TestEditResultsView />}

      {/* Share View */}
      {currentTab === 'Share' && 'Share'}
    </Container>
  );
}
