const keys = {
  general: {
    categories: {
      fetchCategories: 'fetchCategories',
    },
    tests: {
      fetchTest: 'fetchTest',
    },
    premises: {
      fetchPremises: 'fetchPremises',
    },
  },
  staff: {
    staffs: {
      fetchAllStaffs: 'staffs',
      fetchStaff: 'staff',
    },
    premises: {
      fetchAllPremises: 'premises',
      fetchPremise: 'premise',
    },
    tests: {
      fetchTests: 'fetchTests',
      fetchTest: 'fetchTest',
      fetchQuestionsForTest: 'fetchQuestionsForTest',
    },
    questions: {
      fetchAllQuestions: 'questions',
      fetchQuestion: 'question',
    },
    categories: {
      fetchAllCategories: 'categories',
      fetchCategory: 'category',
      fetchTestForCategories: 'tests',
    },
    notifications: {
      templates: {
        templates: 'templates',
        template: 'template',
        templateByRole: (role: string) => `template ${role}`,
      },
      settings: {
        setting: 'setting',
      },
    },
    students: {
      students: 'students',
      student: 'student',
      results: 'results',
      result: 'result',
      notifications: 'notifications',
    },
    account: 'account',
  },
};

export default keys;
