import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, useFieldArray } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { IUseBooleanReturnType } from 'src/hooks/use-boolean';

import keys from 'src/constants/query-keys';
import { createOrUpdateTest } from 'src/api/staff/tests';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';

function TestNewDialog({ dialog }: { dialog: IUseBooleanReturnType }) {
  const queryClient = useQueryClient();

  const router = useRouter();

  const createOrUpdateTestSchema = Yup.object().shape({
    id: Yup.string().optional(),
    name: Yup.string().required('Test name is required'),
    instruction: Yup.string().required('Instructions is required'),
    isRandomized: Yup.boolean().default(false),
    dimension: Yup.number().optional(),
    categoryId: Yup.string().optional(),
    totalQuestions: Yup.number().required(),
    testDuration: Yup.number().default(3600),
    isPublished: Yup.boolean().required(),
    isArchived: Yup.boolean().required(),
    levels: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional(),
          name: Yup.string().required('Level name is required'),
          minPercentage: Yup.number().min(1).max(100).required('Valid percentage is required'),
        }),
      )
      .min(1, 'A level is required'),
  });

  type CreateOrUpdateTest = Yup.InferType<typeof createOrUpdateTestSchema>;

  const defaultValues: CreateOrUpdateTest = {
    name: '',
    instruction: '',
    isRandomized: false,
    totalQuestions: 0,
    testDuration: 3600,
    isPublished: true,
    isArchived: false,
  };

  const methods = useForm({
    resolver: yupResolver(createOrUpdateTestSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const [newLevelName, setNewLevelName] = useState('');
  const [newLevelPercentage, setNewLevelPercentage] = useState(1);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'levels',
  });
  const handleAddLevel = () => {
    append({ name: newLevelName, minPercentage: newLevelPercentage });
    setNewLevelName('');
    setNewLevelPercentage(1);
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const resData = await createOrUpdateTest(data);
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      return router.push(`${paths.staff.test.tests.edit(resData.data.data.testId)}?new=true`);
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const closeDialog = () => {
    dialog.onFalse();
  };

  return (
    <Dialog
      open={dialog.value}
      onClose={closeDialog}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6" component="span" fontWeight="bold">
            New Test
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <RHFTextField name="name" label="Name" size="small" margin="dense" fullWidth />

          <Box>
            <RHFEditor
              simple
              name="instruction"
              placeholder="Instructions"
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
            />
          </Box>
          <Stack spacing={2}>
            <Grid container spacing={3} sx={{ m: 0, width: '100% !important' }}>
              <Grid item xs={12} sm={8} sx={{ m: 0, p: '0 !important' }}>
                <Typography
                  component={Typography}
                  variant="subtitle2"
                  fontWeight="fontWeightBold"
                  marginBottom={1}
                >
                  Levels*
                </Typography>

                <Stack gap={2} direction="row" alignItems="start">
                  <RHFTextField
                    name="levels"
                    value={newLevelName}
                    onChange={(e) => setNewLevelName(e.target.value)}
                    size="small"
                    id="level-name-input"
                    placeholder="Level"
                  />
                  <RHFTextField
                    name="levels.minPercentage"
                    value={newLevelPercentage}
                    onChange={(e) => setNewLevelPercentage(+e.target.value)}
                    type="number"
                    label="%"
                    size="small"
                    helperText=""
                    ignoreErrorMessage
                    sx={{
                      width: '20%',
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={handleAddLevel}
                    disabled={!newLevelName.trim()}
                    endIcon={<Iconify icon="mingcute:add-line" />}
                    sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }}
                  >
                    Add
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            {fields.map((field, index) => (
              <Grid
                key={index}
                container
                gap={2}
                sx={{ m: 0, width: '100% !important' }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sx={{ m: 0, p: '0 !important', display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="akar-icons:dot-grid" width="20px" color="grey.600" />
                </Grid>
                <Grid item xs={8} sx={{ m: 0, p: '0 !important' }}>
                  <RHFTextField
                    name={`levels.${index}.name`}
                    defaultValue={field.name}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1} sx={{ m: 0, p: '0 !important' }}>
                  <RHFTextField
                    name={`levels.${index}.minPercentage`}
                    type="number"
                    label="%"
                    size="small"
                    fullWidth
                    helperText=""
                    ignoreErrorMessage
                  />
                </Grid>
                <Grid item sx={{ m: 0, p: '0 !important' }}>
                  <Button
                    onClick={() => remove(index)}
                    endIcon={<Iconify icon="mdi:close" />}
                    sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton color="success" type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
          <Button onClick={closeDialog} variant="outlined" color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default TestNewDialog;
