import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useResponsive } from 'src/hooks/use-responsive';

import { _tags } from 'src/_mock';
import { createFileUploadLink } from 'src/api/utils';
import { QUESTION_MEDIA_TYPES } from 'src/constants/questions';
import { createQuestion, updateQuestion } from 'src/api/question';
import { NewQuestionSchema } from 'src/schemas/new-edit-question';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import InputSectionDetails from 'src/components/custom-input-section-details/input-section-details';
import FormProvider, {
  RHFEditor,
  RHFSwitch,
  RHFTextField,
  RHFAutocomplete,
  RHFUploadBoxCustom,
} from 'src/components/hook-form';

import { IQuestionType, IQuestionItem, IQuestionAnswer } from 'src/types/question';

import AnswersFields from './question-answers-fields';

// ----------------------------------------------------------------------

type Props = {
  currentQuestion?: IQuestionItem;
};

export default function ProductNewEditForm({ currentQuestion }: Props) {
  const router = useRouter();

  const queryClient = useQueryClient();

  const isEditing = !!currentQuestion?.id;

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      ...(currentQuestion || {}),
      question: currentQuestion?.question || '',
      questionType: currentQuestion?.questionType || null,
      answers:
        currentQuestion?.answers?.map((answer) => ({
          text: answer.text || '',
          positivePoint: (answer.points > 0 && answer.points) || 0,
          negativePoint: (answer.points < 0 && answer.points) || 0,
        })) || [],
      isPublished: currentQuestion?.isPublished ?? true,
      //
      instruction: currentQuestion?.instruction || '',
      mediaPath:
        currentQuestion?.imagePath ||
        currentQuestion?.audioPath ||
        currentQuestion?.videoPath ||
        null,
      mimeType: currentQuestion?.mimeType || undefined,
      tags: currentQuestion?.tags || [],
    }),
    [currentQuestion],
  );

  const methods = useForm({
    resolver: yupResolver(NewQuestionSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    // getValues,
    watch,
    formState: { isSubmitting },
  } = methods;

  const mimeTypeField = watch('mimeType');

  const [editingQuestionMediaTab, setEditingQuestionMediaTab] = useState('');

  useEffect(() => {
    if (currentQuestion) {
      reset(defaultValues);
      if (currentQuestion.mimeType) {
        let currMedia = '';
        Object.entries(QUESTION_MEDIA_TYPES).find(([mediaType, config]) => {
          if (defaultValues.mimeType! in config.accept) {
            currMedia = mediaType;
            return true; // Exit the loop once a match is found
          }
          return false;
        });
        setEditingQuestionMediaTab(currMedia);
        setCurrentTab(currMedia);
      }
    }
  }, [currentQuestion, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { answers, questionType, mediaPath, tags, mimeType, ...restFormData } = data;

      let mediaAndMimeType = {};

      if (mediaPath) {
        const uploadPath = mediaPath?.preview
          ? await handleImageUpload(mediaPath, mimeType as string)
          : mediaPath;

        mediaAndMimeType = {
          ...(editingQuestionMediaTab && {
            [QUESTION_MEDIA_TYPES[editingQuestionMediaTab]?.dbField]: null,
          }),
          [QUESTION_MEDIA_TYPES[currentTab].dbField]: uploadPath,
          mimeType,
        };
      } else if (editingQuestionMediaTab) {
        mediaAndMimeType = {
          [QUESTION_MEDIA_TYPES[editingQuestionMediaTab]?.dbField]: null,
          mimeType: null,
        };
      }

      let totalPoints: number = 0;
      const modifiedAnswersObject: IQuestionAnswer[] = answers!.map((answer) => {
        const { text, negativePoint, positivePoint } = answer;
        if (questionType === 'multiple') {
          totalPoints += +positivePoint;
        } else if (totalPoints < positivePoint) {
          totalPoints = +positivePoint;
        }
        return {
          text,
          points: positivePoint || negativePoint,
        };
      });

      const finalData: IQuestionItem = {
        ...restFormData,
        questionType: questionType as IQuestionType,
        answers: modifiedAnswersObject,
        tags: tags as string[],
        totalPoints,
        videoPath: null,
        ...mediaAndMimeType,
      };

      if (!isEditing) {
        await createQuestion(finalData);
      } else {
        await updateQuestion(finalData);
      }

      queryClient.invalidateQueries({ queryKey: ['questions'] });

      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar(isEditing ? 'Update success!' : 'Create success!');
      router.push(paths.staff.test.questions.root);
    } catch (error) {
      console.error(error);
    }
  });

  const handleImageUpload = async (media: any, mimeType: string) => {
    try {
      if (media) {
        const {
          data: { data: presignedPostData },
        } = await createFileUploadLink({
          mimeType,
          itemType: currentTab,
        });

        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('file', media);
        await axios({
          method: 'post',
          url: presignedPostData.url,
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data;`,
          },
        });

        return presignedPostData.fields.key;
      }
      return '';
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      return '';
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('mediaPath', newFile, { shouldValidate: true });
        setValue('mimeType', newFile.type);
      }
    },
    [setValue],
  );

  const handleRemoveFile = useCallback(() => {
    setValue('mediaPath', null);
  }, [setValue]);

  const [currentTab, setCurrentTab] = useState(Object.keys(QUESTION_MEDIA_TYPES)[0]);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
      handleRemoveFile();
    },
    [handleRemoveFile],
  );

  const renderDetails = (
    <>
      {mdUp && <InputSectionDetails label="Content" details="Title, content, media" />}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Content" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="question" label="Question" />

            <RHFEditor
              simple
              name="instruction"
              placeholder="Instructions for the question"
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
            />

            <Stack spacing={1}>
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                sx={{
                  width: 1,
                }}
              >
                {Object.keys(QUESTION_MEDIA_TYPES).map((type) => (
                  <Tab
                    key={type}
                    sx={{ flexGrow: 1, fontWeight: 'fontWeightBold' }}
                    value={type}
                    iconPosition="end"
                    icon={<Iconify icon={QUESTION_MEDIA_TYPES[type].iconifyIcon} width={24} />}
                    label={QUESTION_MEDIA_TYPES[type].label}
                  />
                ))}
              </Tabs>

              <RHFUploadBoxCustom
                thumbnail
                acceptingType={QUESTION_MEDIA_TYPES[currentTab].accept}
                name="mediaPath"
                // maxSize={QUESTION_MEDIA_TYPES[currentTab].maxSize || 10}
                onDrop={handleDrop}
                onDelete={handleRemoveFile}
                mimeType={mimeTypeField}
                // onUpload={() => console.info('ON UPLOAD')}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderAnswerTypes = (
    <>
      {mdUp && (
        <InputSectionDetails
          label="Answers"
          details="Select answer typology and add correct answers and options"
        />
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Answers" />}

          <AnswersFields />
        </Card>
      </Grid>
    </>
  );

  const renderTags = (
    <>
      {mdUp && <InputSectionDetails label="Tags" details="Add tags and create the question" />}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Tags" />}

          <Stack sx={{ p: 3 }}>
            <RHFAutocomplete
              name="tags"
              label="Tags"
              placeholder="+ Tags"
              multiple
              freeSolo
              options={_tags.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option}
                    label={option}
                    size="small"
                    color="info"
                    variant="soft"
                  />
                ))
              }
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} gap={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }}>
          <RHFSwitch name="isPublished" label="Publish" />
        </Box>

        <Button
          component={RouterLink}
          href={paths.staff.test.questions.root}
          size="large"
          color="inherit"
          variant="outlined"
          sx={{ alignSelf: 'center' }}
        >
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!isEditing ? 'Create' : 'Save'}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderAnswerTypes}

        {renderTags}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
