import { Box, Typography } from '@mui/material';

function NoQuestionsAdded() {
  return (
    <Box
      sx={{
        minHeight: '118px',
        backgroundColor: 'grey.100',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: '2rem',
      }}
    >
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', color: 'grey.500', fontSize: '14px', fontWeight: '400' }}
      >
        0 questions added
      </Typography>
    </Box>
  );
}

export default NoQuestionsAdded;
