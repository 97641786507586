import Box from '@mui/material/Box';

// import { usePathname } from 'src/routes/hooks';

import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export const PADDING_TOP = 20;

export default function MainLayout({ children }: Props) {
  // const pathname = usePathname();

  // const homePage = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // ...(!homePage && {
          pt: PADDING_TOP,
          // }),
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
}
