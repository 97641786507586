import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Box, Tab, Tabs, Stack, Container, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useGetPremise } from 'src/api/premises';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { IPremisesItem } from 'src/types/premises';

import GeneralTab from '../details/general-tab';
import StudentTab from '../details/student-tab';

type props = {
  id: string | any;
};

export const PREMISE_TAB = {
  general: '0',
  students: '1',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PremiseEditView({ id }: props) {
  const settings = useSettingsContext();
  const { data: premise }: any = useGetPremise({ id });
  const [currentTab, setCurrentTab] = React.useState(PREMISE_TAB.general);

  const premiseData: IPremisesItem = React.useMemo(() => premise || {}, [premise]);
  const query = useQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    setCurrentTab(query.get('activeTab') || PREMISE_TAB.general);
  }, [query]);

  const handleTabChange = (_: any, newValue: string) => {
    if (newValue === PREMISE_TAB.general) {
      navigate('.', { replace: true });
    } else {
      navigate(`?activeTab=${newValue}`);
    }
    setCurrentTab(newValue);
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={premiseData?.name || 'Premises'}
        links={[
          { name: 'Dashboard', href: '#' },
          { name: 'Premises', href: paths.staff.premises },
          { name: premiseData?.name || 'premises' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        mb={2}
      >
        <Tabs value={currentTab} onChange={handleTabChange} sx={{ marginBottom: '1rem' }}>
          <Tab
            label={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Iconify icon="mdi:card-account-details" />
                <Typography>General</Typography>
              </Stack>
            }
            value={PREMISE_TAB.general}
          />
          <Tab
            label={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Iconify icon="mdi:account-multiple" />
                <Typography>Students</Typography>
              </Stack>
            }
            value={PREMISE_TAB.students}
          />
        </Tabs>
      </Box>
      {currentTab === PREMISE_TAB.general && <GeneralTab premiseDetails={premiseData} />}
      {currentTab === PREMISE_TAB.students && <StudentTab />}
    </Container>
  );
}
