import { useSearchParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useGetData } from 'src/hooks/use-get-data';

// import { useGetQuestion } from 'src/api/question';

import { endpoints } from 'src/utils/axios';

import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { IFetchBaseParams } from 'src/types/misc';

import QuestionNewEditForm from '../question-new-edit-form';

export default function QuestionEditView({ id }: IFetchBaseParams) {
  const [searchParam] = useSearchParams();
  const isNew = searchParam.get('new') === 'true';

  const settings = useSettingsContext();
  const { data: question, isPending }: any = useGetData({
    args: [`${endpoints.staff.questions}/${id}`],
    queryKey: ['questions', id],
  });

  if (!isPending && isNew) {
    delete question?.id;
  }

  if (isPending)
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <LoadingScreen />
      </Container>
    );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Questions"
        links={[
          { name: 'Dashboard', href: '#' },
          { name: 'Questions', href: paths.staff.test.questions.root },
          { name: isNew ? 'Duplicate Question' : 'Edit question', href: '#' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <QuestionNewEditForm currentQuestion={question} />
    </Container>
  );
}
