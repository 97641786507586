import { UseQueryResult } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import keys from 'src/constants/query-keys';

import { IPremisesPublic } from 'src/types/public/premises';

export function useGetHomeCategories() {
  return useGetData({
    args: [endpoints.general.categories],
    queryKey: [keys.general.categories.fetchCategories],
  });
}

export async function getAccountRole() {
  const {
    data: { data },
  } = await axiosInstance.get(endpoints.staff.account);
  return data.role;
}

export function useGetTestDetails({ id }: { id: string }) {
  return useGetData({
    args: [endpoints.general.tests.details(id)],
    queryKey: [keys.general.tests.fetchTest],
  });
}

export function useGetPremisesListForPublic(params: {
  all: boolean;
  search: string;
}): UseQueryResult<IPremisesPublic, Error> {
  return useGetData({
    args: [endpoints.general.premises, { params }],
    queryKey: [keys.general.premises.fetchPremises, params],
    options: {
      enabled: Boolean(params.search),
    },
  });
}
