import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import { useMemo, Dispatch, SetStateAction } from 'react';

import {
  Box,
  Card,
  Stack,
  alpha,
  Button,
  Divider,
  Checkbox,
  TextField,
  Typography,
  CardContent,
} from '@mui/material';

import uuidv4 from 'src/utils/uuidv4';
import { isQuestionOrSection } from 'src/utils/misc';
import { lightenHexColor } from 'src/utils/lighten-hex-color';

import { ANSWERS_TYPES_CONFIG } from 'src/constants/questions';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import { ITestQuestion } from 'src/types/staff/tests';

function TestQuestionsBox({
  question,
  id,
  handleEditQuestion,
  handleQuestionSelect,
  selectedQuestions,
  setDndItems,
}: {
  question: ITestQuestion;
  id: string;
  handleEditQuestion: (questionId: string) => void;
  handleQuestionSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    question: ITestQuestion,
  ) => void;
  selectedQuestions: ITestQuestion[];
  setDndItems: Dispatch<SetStateAction<any>>;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: transform ? 999 : 'auto',
      }
    : undefined;

  const { getValues, setValue } = useFormContext();

  const isSelected = useMemo(
    () => selectedQuestions.some((q) => q.id === id),
    [id, selectedQuestions],
  );

  const getMaxPoints = (): number => {
    if (question.questionType === 'single' || question.questionType === 'text') {
      return Math.max(...question.answers.map((ans) => ans.points));
    }
    return question.answers.reduce((acc, curr) => acc + (curr.points > 0 ? curr.points : 0), 0);
  };

  const correctAnswer = question.answers.reduce(
    (highest, current) => (current.points > highest.points ? current : highest),
    question.answers[0] || { text: '', points: 0 },
  );

  const handleRemoveQuestion = () => {
    const formValues = getValues();
    const { independentQuestions, sections } = formValues;

    const currentQuestionId = question._tempId || question.id;

    // Check if the question is in independentQuestions
    const independentIndex = independentQuestions.findIndex(
      (q: { _tempId: string; id: string }) =>
        q._tempId === currentQuestionId || q.id === currentQuestionId,
    );

    if (independentIndex !== -1) {
      // Remove from independentQuestions
      const updatedIndependents = [
        ...independentQuestions.slice(0, independentIndex),
        ...independentQuestions.slice(independentIndex + 1),
      ];
      setValue('independentQuestions', updatedIndependents);

      setDndItems((prevItems: any) => {
        const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
        return prevItemsCopy.filter(
          (item: { _tempId: string; id: string }) =>
            item._tempId !== currentQuestionId && item.id !== currentQuestionId,
        );
      });
    } else {
      // Assume the question is inside a section
      const updatedSections = sections.map((section: { questions: any[] }) => {
        const questionIndex = section.questions.findIndex(
          (q: { _tempId: string; id: string }) =>
            q._tempId === currentQuestionId || q.id === currentQuestionId,
        );
        if (questionIndex !== -1) {
          return {
            ...section,
            questions: [
              ...section.questions.slice(0, questionIndex),
              ...section.questions.slice(questionIndex + 1),
            ],
          };
        }
        return section;
      });
      setValue('sections', updatedSections);

      setDndItems((prevItems: any) => {
        const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
        return prevItemsCopy.map((item: { questions: any[] }) => {
          if (isQuestionOrSection(item) === 'section') {
            const questionIndex = item.questions.findIndex(
              (q: { _tempId: string; id: string }) =>
                q._tempId === currentQuestionId || q.id === currentQuestionId,
            );
            if (questionIndex !== -1) {
              return {
                ...item,
                questions: [
                  ...item.questions.slice(0, questionIndex),
                  ...item.questions.slice(questionIndex + 1),
                ],
              };
            }
          }
          return item;
        });
      });
    }
  };

  const handleDuplicateQuestion = () => {
    const formValues = getValues();
    const { independentQuestions, sections } = formValues;

    // if (dimension && totalQuestions >= dimension) {
    //   enqueueSnackbar('Unable to duplicate question. Exceeding dimensions', { variant: 'error' });
    //   return;
    // }

    // Check if the question is in independentQuestions
    const independentIndex = independentQuestions.findIndex(
      (q: { id: string }) => q.id === question.id,
    );
    if (independentIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _id, ...rest } = question;
      const newQuestion = {
        ...rest,
        _tempId: uuidv4(),
        position: question.position + 1,
      };
      const updatedIndependents = [
        ...independentQuestions.slice(0, independentIndex + 1),
        newQuestion,
        ...independentQuestions.slice(independentIndex + 1).map((q: { position: number }) => ({
          ...q,
          position: q.position + 1,
        })),
      ];
      setValue('independentQuestions', updatedIndependents);

      setDndItems((prevItems: any) => {
        const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
        const independentItemIndex = prevItemsCopy.findIndex(
          (q: { id: string }) => q.id === question.id,
        );

        if (independentIndex !== -1) {
          const newItem = {
            ...newQuestion,
            id: newQuestion._tempId,
            position: independentItemIndex + 1,
          };
          const updatedItems = [
            ...prevItemsCopy.slice(0, independentItemIndex + 1),
            newItem,
            ...prevItemsCopy.slice(independentItemIndex + 1).map((q: { position: number }) => ({
              ...q,
              position: q.position + 1,
            })),
          ];
          return updatedItems;
        }

        return prevItemsCopy;
      });
    } else {
      // Assume the question is inside a section
      const updatedSections = sections.map((section: { id: string; questions: any[] }) => {
        if (section.id === question.sectionId) {
          const questionIndex = section.questions.findIndex(
            (q: { id: string }) => q.id === question.id,
          );
          if (questionIndex !== -1) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id: _id, ...rest } = question;
            const newQuestion = {
              ...rest,
              _tempId: uuidv4(),
              position: question.position + 1,
            };
            return {
              ...section,
              questions: [
                ...section.questions.slice(0, questionIndex + 1),
                newQuestion,
                ...section.questions.slice(questionIndex + 1).map((q) => ({
                  ...q,
                  position: q.position + 1,
                })),
              ],
            };
          }
        }
        return section;
      });
      setValue('sections', updatedSections);

      setDndItems((prevItems: any) => {
        const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));

        const updatedItems = prevItemsCopy.map((item: Record<string, any>) => {
          if (isQuestionOrSection(item) === 'section' && item.id === question.sectionId) {
            const questionIndex = item.questions.findIndex(
              (q: { id: string }) => q.id === question.id,
            );

            if (questionIndex !== -1) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id: _id, ...rest } = question;
              const newQuestion = {
                ...rest,
                id: uuidv4(),
                position: question.position + 1,
              };
              return {
                ...item,
                questions: [
                  ...item.questions.slice(0, questionIndex + 1),
                  newQuestion,
                  ...item.questions.slice(questionIndex + 1).map((q: { position: number }) => ({
                    ...q,
                    position: q.position + 1,
                  })),
                ],
              };
            }
          }
          return item;
        });

        return updatedItems;
      });
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleQuestionSelect(event, question);
  };

  return (
    <Stack ref={setNodeRef} style={style} alignItems="center" direction="row" width="100%">
      <Stack alignItems="center" direction="row">
        <Iconify
          icon="akar-icons:dot-grid"
          width="20px"
          color="grey.600"
          {...listeners}
          {...attributes}
          sx={{
            cursor: 'pointer',
          }}
        />
        <Typography
          variant="body2"
          sx={{
            mx: '0.75rem',
            color: 'grey.600',
            fontWeight: '900',
            fontSize: '16px',
            lineHeight: '16px',
          }}
        >
          {question.position}
        </Typography>

        <Checkbox
          sx={{
            mr: '1rem',
            p: '0.25rem',
          }}
          checked={isSelected}
          onChange={handleSelectChange}
        />
      </Stack>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          boxShadow: (theme) => (isSelected ? theme.customShadows.z8 : theme.customShadows.z4),
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" gap={4}>
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  bgcolor: 'grey.300',
                  borderRadius: 3,
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Iconify
                  icon={ANSWERS_TYPES_CONFIG[question.questionType]?.iconifyIcon}
                  width={20}
                  color="grey.600"
                />
              </Box>
              <Stack>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 16,
                    fontWeight: '900',
                    color: 'info.dark',
                    lineHeight: '24px',
                    mb: '8px',
                  }}
                >
                  {question.question}
                </Typography>
                <Stack direction="row" alignItems="center" gap="4px" marginBottom="16px">
                  <Iconify icon="lets-icons:check-fill" width={18} color="success.main" />
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '18px',
                      color: 'success.main',
                    }}
                  >
                    {correctAnswer.text}
                  </Typography>
                </Stack>
                {question.answers.map(
                  (answer, idx) =>
                    answer.text !== correctAnswer.text && (
                      <Stack
                        key={idx}
                        direction="row"
                        alignItems="center"
                        gap="4px"
                        marginBottom="6px"
                      >
                        <Iconify icon="gridicons:cross-circle" width={16} color="grey.500" />
                        <Typography
                          key={idx}
                          variant="body1"
                          sx={{
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: 'grey.500',
                          }}
                        >
                          {answer.text}
                        </Typography>
                      </Stack>
                    ),
                )}
              </Stack>
            </Stack>
            <TextField
              label="Max points"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              size="medium"
              type="number"
              defaultValue={getMaxPoints()}
              sx={{
                maxWidth: '96px',
              }}
            />
          </Stack>

          <Divider sx={{ borderStyle: 'dashed', my: '1.5rem' }} />

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={3}>
              <Button
                disabled={isSelected}
                sx={{
                  fontSize: '13px',
                  fontWeight: '700',
                  color: 'customColors.custom1',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                variant="text"
                onClick={handleDuplicateQuestion}
              >
                Duplicate
                <Iconify
                  icon="pajamas:duplicate"
                  width={20}
                  marginLeft={1}
                  sx={{ color: !isSelected ? 'customColors.custom4' : 'grey.500' }}
                />
              </Button>
              <Button
                disabled={isSelected}
                sx={{
                  fontSize: '13px',
                  fontWeight: '700',
                  color: 'customColors.custom1',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                variant="text"
                onClick={() => handleEditQuestion(question._tempId || question.id)}
              >
                Edit
                <Iconify
                  icon="fluent:edit-12-filled"
                  width={20}
                  marginLeft={1}
                  sx={{ color: !isSelected ? 'customColors.custom4' : 'grey.500' }}
                />
              </Button>
              <Button
                disabled={isSelected}
                variant="contained"
                endIcon={<Iconify icon="radix-icons:cross-2" />}
                sx={{
                  minWidth: '90px',
                  backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                  color: 'customColors.custom5',
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                }}
                onClick={handleRemoveQuestion}
              >
                Remove
              </Button>
            </Stack>

            <Stack direction="row" spacing={1}>
              {question.tags.map((tag, idx) => (
                <Label
                  key={idx}
                  variant="soft"
                  sx={{
                    backgroundColor: lightenHexColor('#0079CC', 80),
                    color: 'customColors.custom3',
                    fontWeight: '900',
                    fontSize: '13px',
                    lineHeight: '18px',
                  }}
                >
                  {tag}
                </Label>
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default TestQuestionsBox;
