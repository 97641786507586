import { UseQueryResult } from '@tanstack/react-query';

import { useGetData, usePostData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import keys from 'src/constants/query-keys';

import { ICategoryItem, ICategoryList } from 'src/types/category';

export function useGetCategoriesList(): UseQueryResult<ICategoryList[], Error> {
  return useGetData({
    args: [endpoints.staff.categories],
    queryKey: [keys.staff.categories.fetchAllCategories],
  });
}

export function useGetCategoryTestList(data: any) {
  return usePostData({
    args: [endpoints.staff.test.category, { ...data }],
    queryKey: [
      keys.staff.categories.fetchCategory,
      keys.staff.categories.fetchTestForCategories,
      { data },
    ],
  });
}

export async function createCategory(data: ICategoryItem) {
  const URL = `${endpoints.staff.categories}`;
  const resData = await axiosInstance.post(URL, data);
  return resData.data;
}

export function useGetCategoryById(id: string): UseQueryResult<ICategoryItem, Error> {
  return useGetData({
    args: [`${endpoints.staff.categories}/${id}`],
    queryKey: [keys.staff.categories.fetchCategory, id],
  });
}

export async function updateCategory(data: Partial<ICategoryItem>) {
  const URL = `${endpoints.staff.categories}`;
  await axiosInstance.post(URL, data);
}

export async function archiveCategory(id: string) {
  const URL = `${endpoints.staff.categories}/${id}/archive`;
  await axiosInstance.post(URL);
}

export async function toggleCategoryActiveField(id: string) {
  const URL = `${endpoints.staff.categories}/${id}/active`;
  await axiosInstance.post(URL);
}

export async function updateTestInCategory(id: string, data: any) {
  const URL = `${endpoints.staff.categories}/${id}`;
  await axiosInstance.post(URL, data);
}
