import { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Box, Link, Switch, MenuItem, Typography, FormControlLabel } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { lightenHexColor } from 'src/utils/lighten-hex-color';

import keys from 'src/constants/query-keys';
import { archiveTest, updateTestPublishStatus } from 'src/api/staff/tests';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import usePopover from 'src/components/custom-popover/use-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';

import { ITestTableItem } from 'src/types/staff/tests';

// ----------------------------------------------------------------------

type Props = {
  row: ITestTableItem;
};

export default function TestsTableRow({ row }: Props) {
  const { id, name, totalQuestions, isPublished, category, unlocksAt, totalResults } = row;

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [activeStatus, setActiveStatus] = useState(isPublished);

  const confirm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleTestDeletion = async () => {
    confirm.onFalse();
    if (!(unlocksAt > 0)) {
      enqueueSnackbar(`${name} can't be deleted as it's a primary test in a category`, {
        variant: 'error',
      });
      return;
    }
    await archiveTest(id!);
    enqueueSnackbar(`Test Deleted`);
    queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
  };

  const isLiveToggling = useBoolean(false);

  const handleLiveToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    const status = e.currentTarget.checked;
    try {
      isLiveToggling.onTrue();
      await updateTestPublishStatus(id, { status });
      setActiveStatus(status);
      isLiveToggling.onFalse();
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      enqueueSnackbar(`${name} has been ${status ? 'published' : 'unpublished'}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error?.message ? error?.message : JSON.stringify(error || '{}'), {
        variant: 'error',
      });
      isLiveToggling.onFalse();
    }
  };

  const redirectUrl = `${paths.staff.test.tests.edit(id)}`;

  const handleEditNavigation = (openInNewTab = false) => {
    // const url = `${paths.staff.test.tests.edit(id)}`; // Construct the URL
    if (openInNewTab) {
      // Open in a new tab
      window.open(redirectUrl, '_blank', 'noopener,noreferrer');
    } else {
      // Use React Router to navigate within the app
      navigate(redirectUrl);
    }

    // Close the popover if it's open
    if (popover.open) {
      popover.onClose();
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: '900', lineHeight: '22px', color: '#000000', cursor: 'pointer' }}
          >
            <Link
              color="inherit"
              underline="hover"
              href={redirectUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </Link>
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontWeight: '400', lineHeight: '22px', color: 'grey.500' }}
          >
            {totalResults} results
          </Typography>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {category && (
            <Label
              variant="soft"
              sx={{
                backgroundColor: lightenHexColor(category.color, 88),
                color: category.color,
                fontWeight: '700',
                fontSize: '12px',
                lineHeight: '20px',
              }}
            >
              {category && category.name}
            </Label>
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!(unlocksAt > 0) ? <Iconify icon="material-symbols:check" color="success.main" /> : ''}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="body2" sx={{ fontWeight: '400', lineHeight: '22px' }}>
            {totalQuestions}
          </Typography>
        </TableCell>

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <FormControlLabel
            control={<Switch checked={activeStatus} color="success" onChange={handleLiveToggle} />}
            label="Live"
          />
        </TableCell>
        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton onClick={() => handleEditNavigation(true)}>
            <Iconify icon="eva:external-link-outline" />
          </IconButton>
        </TableCell>

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton>
            <Iconify icon="solar:share-bold" />
          </IconButton>
        </TableCell>

        <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="ic:twotone-delete" /> Delete Test?
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1">Are you sure you want to delete this Test?</Typography>
            <Typography variant="body1">
              Please remember that you can only delete non-primary tests.
            </Typography>
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={handleTestDeletion}>
            Delete
          </Button>
        }
      />

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 100, p: 0 }}>
        <MenuItem
          onClick={() => handleEditNavigation(false)}
          sx={{ m: 1, fontWeight: '600', color: 'info.dark' }}
        >
          Edit
        </MenuItem>
        <MenuItem sx={{ m: 1, fontWeight: '600', color: 'error.dark' }} onClick={confirm.onTrue}>
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  );
}
