import { isEqual } from 'lodash';
import { useMemo, useState, useCallback } from 'react';

import { Card, Table, Button, Container, TableBody, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { useGetTests } from 'src/api/staff/tests';
import { TABLE_DEFAULTS } from 'src/constants/misc';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { ITestTableItem, ITestsTableFilters, ITestsTableFilterValue } from 'src/types/staff/tests';

import TestTableRow from '../test-table-row';
import TestNewDialog from '../test-new-dialog';
import TestTableToolbar from '../test-table-toolbar';
import TestTableFiltersResult from '../test-table-filters-result';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', width: 300, align: 'left' },
  { id: 'category', label: 'Category', width: 220, align: 'left' },
  { id: 'primary', label: 'Primary', width: 132, align: 'left' },
  { id: 'noOfQuestions', label: 'N° questions', width: 100, align: 'left' },
  { id: 'isPublished', label: '', width: 50, align: 'right' },
  { id: 'open', label: '', width: 50, align: 'right' },
  { id: 'share', label: '', width: 50, align: 'right' },
  { id: 'menu', label: '', width: 50, align: 'right' },
];

const defaultFilters: ITestsTableFilters = {
  categoryId: '',
  categoryName: '',
  searchQuery: '',
  status: '',
};

export default function TestsListView() {
  const settings = useSettingsContext();
  const dialog = useBoolean();

  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });

  const [filters, setFilters] = useState(defaultFilters);

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            pageSize: table.rowsPerPage,
          }),
      sortColumn: table.orderBy,
      sortOrder: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: ITestsTableFilters = { ...filters };
    if (allFilters.searchQuery) {
      delete allFilters.searchQuery;
    }

    if (allFilters.status) {
      allFilters.isPublished = allFilters.status === 'Published';
    }

    return allFilters;
  }, [filters]);

  const [debouncedsearchQuery] = useDebounce(filters.searchQuery || '', 1500);

  const apiFilters: ITestsTableFilters = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      searchQuery: debouncedsearchQuery,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedsearchQuery]);

  const { data, isPending }: any = useGetTests({
    ...apiFilters,
  });

  const {
    // currentPage = TABLE_DEFAULTS.CURRENT_PAGE,
    // totalPages = TABLE_DEFAULTS.TOTAL_PAGES,
    tests: tableData = [],
    totalCount = 0,
    // pageSize = TABLE_DEFAULTS.ROWS_PER_PAGE,
  }: {
    tests: ITestTableItem[];
    totalCount: number;
  } = useMemo(
    () => ({
      // currentPage: data?.currentPage,
      // totalPages: data?.totalPages,
      tests: data?.data,
      totalCount: data?.totalCount,
      // pageSize: data?.pageSize,
    }),
    [data],
  );

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleFilters = useCallback(
    (name: string, value: ITestsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Tests"
        links={[{ name: 'Dashboard', href: '#' }, { name: 'Tests' }]}
        action={
          tableData.length > 0 && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={dialog.onTrue}
            >
              New test
            </Button>
          )
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card>
        {isPending || canReset || tableData.length ? (
          <>
            <TestTableToolbar filters={filters} onFilters={handleFilters} />

            {canReset && (
              <TestTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                onResetFilters={handleResetFilters}
                results={totalCount}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={20}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    sx={{ whiteSpace: 'nowrap' }}
                  />

                  <TableBody>
                    {tableData.length > 0 &&
                      tableData.map((row) => <TestTableRow key={row.id} row={row} />)}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={totalCount}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </>
        ) : (
          <EmptyContent
            filled
            title="0 Tests"
            imgUrl="/assets/icons/empty/ic_chat.svg"
            action={
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 1 }}
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={dialog.onTrue}
              >
                New Test
              </Button>
            }
            sx={{ p: 5 }}
          />
        )}
      </Card>
      {dialog.value && <TestNewDialog dialog={dialog} />}
    </Container>
  );
}
