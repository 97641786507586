import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Button,
  Dialog,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useDebounce } from 'src/hooks/use-debounce';
import { IUseBooleanReturnType } from 'src/hooks/use-boolean';

import keys from 'src/constants/query-keys';
import { CATEGORY_COLORS } from 'src/constants/categories';
import { createCategory, useGetCategoryTestList } from 'src/api/categories';

import Iconify from 'src/components/iconify';
import { ColorPicker } from 'src/components/color-utils';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

import { ICategoryItem } from 'src/types/category';

import TestSearch from './categoryies-test-search';

function CategoryNewDialog({ dialog }: { dialog: IUseBooleanReturnType }) {
  const queryClient = useQueryClient();

  const router = useRouter();

  const NewCategorySchema = Yup.object().shape({
    name: Yup.string().required('Category name is required'),
    instructions: Yup.string().required('Instruction is required'),
    color: Yup.string()
      .oneOf(CATEGORY_COLORS)
      .default(CATEGORY_COLORS[0])
      .required('Color is required'),
    primaryTestId: Yup.object({
      name: Yup.string(),
      id: Yup.string(),
    }).test('is primary test have value', 'primary test cannot be empty', (val) => {
      if (!val.id || !val.name) return false;
      return true;
    }),
    isPublished: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
    // not required
    icon: Yup.string(),
  });

  const defaultValues = {
    name: '',
    instructions: '',
    icon: '',
    color: CATEGORY_COLORS[0],
    primaryTestId: {
      name: '',
      id: '',
    },
    isPublished: false,
    isActive: false,
  };

  const methods = useForm({
    resolver: yupResolver(NewCategorySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const [testSearchText, setTestSearchText] = useState('');

  const [debouncedSearchText] = useDebounce(testSearchText, 1500);

  const {
    data: tests = [],
    // isError,
    isPending,
  } = useGetCategoryTestList({
    search: debouncedSearchText,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    const { primaryTestId, ...restFormData } = data;

    const finalData: ICategoryItem = {
      ...restFormData,
      primaryTestId: primaryTestId.id!,
    };

    try {
      const resData = await createCategory(finalData);

      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchAllCategories] });

      return router.push(`${paths.staff.test.categories.editView(resData.data.id)}?new=true`);
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const handleSearch = useCallback((inputValue: string) => {
    setTestSearchText(inputValue);
  }, []);

  const closeDialog = () => {
    dialog.onFalse();
  };

  return (
    <Dialog
      open={dialog.value}
      onClose={closeDialog}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6" component="span" fontWeight="bold">
            New Category
          </Typography>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <RHFTextField name="name" label="Name" size="small" margin="dense" fullWidth />
          <Stack>
            <RHFEditor
              simple
              name="instructions"
              placeholder="Instructions"
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
            />
          </Stack>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7}>
                <FormControl variant="outlined" fullWidth>
                  <Typography
                    // id="demo-customized-radios"
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    Icon
                  </Typography>

                  <RHFTextField
                    name="icon"
                    size="small"
                    id="input-with-icon-textfield"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl>
                  <Typography
                    // id="demo-customized-radios"
                    component={Typography}
                    variant="subtitle2"
                    fontWeight="fontWeightBold"
                    marginBottom={1}
                  >
                    Colors
                  </Typography>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <ColorPicker
                        colors={CATEGORY_COLORS}
                        selected={field.value}
                        onSelectColor={(color) => field.onChange(color as string)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <FormControl variant="outlined" fullWidth>
            <Typography
              // id="demo-customized-radios"
              component={Typography}
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
            >
              Primary Test
            </Typography>
            <TestSearch
              name="primaryTestId"
              query={debouncedSearchText}
              results={tests as any}
              searchInput={testSearchText}
              onSearch={handleSearch}
              loading={isPending}
            />
          </FormControl>
          <Box sx={{ flexGrow: 1 }} flexDirection="column" display="flex">
            <Typography
              // id="demo-customized-radios"
              component={Typography}
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
            >
              Status
            </Typography>
            <RHFSwitch name="isPublished" label="Publish/Unpublish" />
            <RHFSwitch name="isActive" label="Show/Hide in FE" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} variant="outlined" color="inherit">
            Cancel
          </Button>
          <LoadingButton color="success" type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default CategoryNewDialog;
