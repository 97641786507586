import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import queryKeys from 'src/constants/query-keys';
import { updateStudent } from 'src/api/students';
import { useGetPremisesList } from 'src/api/premises';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';

import { IUpdateStudentItem, IStudentDetailsItem } from 'src/types/students';

type Props = {
  studentItem: IStudentDetailsItem;
};

export default function GeneraLab({ studentItem }: Props) {
  const { data: premiseData }: any = useGetPremisesList({ sendAllRows: true });

  const queryClient = useQueryClient();

  const PREMISES_OPTIONS = useMemo(
    () =>
      (premiseData?.data || []).map((pd: { id: string; name: string }) => ({
        label: pd.name,
        value: pd.id,
      })),
    [premiseData],
  );

  const schema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required('Full name required'),
    email: Yup.string().email().required('Email required'),
    phone: Yup.number(),
    city: Yup.string(),
    premisesIds: Yup.array().of(Yup.mixed<any>()),
  });

  const defaultValues = useMemo(
    () => ({
      id: studentItem.id || '',
      name: `${studentItem?.name} ${studentItem?.surname}` || '',
      email: studentItem?.email || '',
      phone: Number(studentItem?.phone),
      city: studentItem.city || '',
      premisesIds:
        PREMISES_OPTIONS.filter((po: { label: string; value: string }) =>
          studentItem?.premises.some((pr) => pr.premise.id === po.value),
        ) || [],
    }),
    [studentItem, PREMISES_OPTIONS],
  );

  const methods = useForm({
    resolver: yupResolver<any>(schema),
    defaultValues,
  });

  const {
    watch,
    reset,
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  const values = watch();

  const handleReset = useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    if (studentItem) handleReset();
  }, [studentItem, reset, defaultValues, handleReset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const names = data.name.split(' ');
      const formatData: IUpdateStudentItem = {
        id: data.id,
        name: names[0],
        surname: names[1] || '',
        city: data.city || '',
        phone: (data.phone && String(data.phone)) || '',
        premisesIds: data.premisesIds.length
          ? data.premisesIds.map((pi: { value: string }) => pi.value)
          : null,
      };

      await updateStudent(removeFalsyValuesFromObject(formatData));

      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff.students.student],
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        reset();
      }
    }
  });

  const content = (
    <Stack spacing={3}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ width: '100%' }}
        spacing={2}
        paddingTop={1}
      >
        <RHFTextField
          name="name"
          sx={{
            width: '100%',
          }}
          label="Name"
          InputLabelProps={{ style: { fontWeight: 900 } }}
          required
        />

        <RHFTextField
          name="email"
          sx={{
            width: '100%',
          }}
          label="Email"
          InputLabelProps={{ style: { fontWeight: 900 } }}
          required
        />
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ width: '100%' }}
        spacing={2}
        paddingTop={1}
      >
        <RHFTextField
          name="phone"
          sx={{
            width: '100%',
          }}
          type="number"
          label="Phone"
          InputLabelProps={{ style: { fontWeight: 900 } }}
        />

        <RHFTextField
          name="city"
          sx={{
            width: '100%',
          }}
          label="City"
          InputLabelProps={{ style: { fontWeight: 900 } }}
        />
      </Stack>
      <RHFAutocomplete
        multiple
        forcePopupIcon={false}
        name="premisesIds"
        options={PREMISES_OPTIONS}
        value={values?.premisesIds}
        label="Premises"
        InputLabelProp={{ style: { fontWeight: 900 } }}
        sx={{
          '& .MuiInputLabel-root': {
            fontWeight: 900,
          },
        }}
        ChipProps={{
          style: { backgroundColor: '#00B8D941', color: '#006C9C', fontWeight: 900 },
        }}
        isOptionEqualToValue={(option, value) =>
          value === '' ? true : option.value === value.value
        }
      />
    </Stack>
  );

  const action = (
    <Stack direction="row" spacing={1.5} justifyContent="end">
      <Button variant="outlined" size="medium" onClick={handleReset}>
        Cancel
      </Button>
      <LoadingButton
        type="submit"
        variant="contained"
        color="success"
        size="medium"
        loading={isSubmitting}
      >
        Save Changes
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        {content}
        {action}
      </Stack>
    </FormProvider>
  );
}
