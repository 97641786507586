import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'src/routes/hooks';

import { TemplateEditView } from 'src/sections/staff/notifications/email/view';

export default function EmailStudentEditViewPage() {
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>Student: Edit</title>
      </Helmet>
      {params?.id && params?.roleType && <TemplateEditView id={params.id} role={params.roleType} />}
    </>
  );
}
