import {
  Button,
  Dialog,
  TextField,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { IUseBooleanReturnType } from 'src/hooks/use-boolean';

import Iconify from '../iconify';

export default function CustomShareDialog({ shareDialog }: { shareDialog: IUseBooleanReturnType }) {
  return (
    <Dialog
      open={shareDialog.value}
      onClose={shareDialog.onFalse}
      fullWidth
      sx={{
        '& .MuiPaper-elevation': {
          maxWidth: '720px',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h6" component="span" fontWeight="bold">
          Share Category
        </Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={shareDialog.onFalse}
        sx={{
          position: 'absolute',
          right: 8,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="mingcute:close-line" />
      </IconButton>

      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <TextField
          autoFocus
          size="small"
          type="text"
          margin="dense"
          variant="outlined"
          placeholder="Search student"
          label="Student"
          sx={{
            flexGrow: 1,
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          endIcon={<Iconify icon="fluent:mail-24-filled" />}
        >
          Send Email
        </Button>
        or
        <Button variant="soft" color="secondary">
          Copy link
        </Button>
      </DialogContent>

      <DialogActions>
        <Button onClick={shareDialog.onFalse} variant="outlined" color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
