import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import { Box, Stack, Button, Divider, MenuItem, InputAdornment } from '@mui/material';

import { NEGATIVE_POINTS, POSITIVE_POINTS } from 'src/constants/questions';

import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';

export default function QuestionAnswer({
  id,
  isSingleQuestionType,
  isMultipleQuestionType,
  isTextQuestionType,
  index,
  handleChangePoints,
  handleRemove,
  answersFieldVal,
}: {
  id: string;
  isSingleQuestionType: boolean;
  isMultipleQuestionType: boolean;
  isTextQuestionType: boolean;
  answersFieldVal: { [key: string]: string | number }[];
  index: number;
  handleChangePoints: (index: number, option: number, field: string) => void;
  handleRemove: (index: number) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ width: 1 }} style={style}>
      <Box ref={setNodeRef} {...attributes} {...listeners}>
        <Iconify
          icon="mdi:drag"
          width={20}
          height={20}
          sx={{
            flexShrink: 0,
            cursor: 'pointer',
          }}
        />
      </Box>

      {(isSingleQuestionType || isMultipleQuestionType) && (
        <Box
          component="img"
          alt="empty content"
          width={20}
          height={20}
          src={
            isSingleQuestionType
              ? '/assets/icons/question/ic_radio_off.svg'
              : '/assets/icons/question/ic_primary_shape.svg'
          }
        />
      )}
      <RHFTextField
        size="small"
        name={`answers[${index}].text`}
        label={isTextQuestionType ? `answer ${index + 1}` : `option ${index + 1}`}
        //   InputLabelProps={{ shrink: true }}
        variant={isTextQuestionType ? 'outlined' : 'standard'}
      />

      <RHFSelect
        name={`answers[${index}].negativePoint`}
        size="small"
        label="Points"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="solar:dislike-linear"
                {...(+answersFieldVal[index].negativePoint < 0 && {
                  sx: {
                    color: 'red',
                  },
                })}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: { md: 90 },
        }}
        {...(+answersFieldVal[index].negativePoint < 0 && {
          SelectPropsSx: {
            color: 'red',
          },
        })}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <MenuItem disabled value={NEGATIVE_POINTS[0]}>
          {NEGATIVE_POINTS[0]}
        </MenuItem> */}

        {NEGATIVE_POINTS.map((point) => (
          <MenuItem
            key={point}
            value={point}
            // onClick={() => {
            //   setValue(`answers[${index}].positivePoint`, 0, { shouldValidate: true });
            //   setValue(`answers[${index}].negativePoint`, Number(point));
            // }}
            onClick={() => handleChangePoints(index, point, 'negativePoint')}
          >
            {point}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        name={`answers[${index}].positivePoint`}
        size="small"
        label="Points"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="solar:like-linear"
                {...(+answersFieldVal[index].positivePoint > 0 && {
                  sx: {
                    color: 'green',
                  },
                })}
              />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: { md: 90 },
        }}
        {...(+answersFieldVal[index].positivePoint > 0 && {
          SelectPropsSx: {
            color: 'green',
          },
        })}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <MenuItem disabled value={POSITIVE_POINTS[0]}>
          {POSITIVE_POINTS[0]}
        </MenuItem> */}
        {POSITIVE_POINTS.map((point) => (
          <MenuItem
            key={point}
            value={point}
            // onClick={() => {
            //   setValue(`answers[${index}].negativePoint`, 0, { shouldValidate: true });
            //   setValue(`answers[${index}].positivePoint`, Number(point));
            // }}
            onClick={() => handleChangePoints(index, point, 'positivePoint')}
          >
            {point}
          </MenuItem>
        ))}
      </RHFSelect>

      <Button
        size="small"
        color="error"
        variant="contained"
        sx={{
          flexShrink: 0,
        }}
        startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        onClick={() => handleRemove(index)}
        disabled={
          index === 0 ||
          (isSingleQuestionType && index === 1) ||
          (isMultipleQuestionType && (index === 1 || index === 2))
        }
      >
        Remove
      </Button>
    </Stack>
  );
}
