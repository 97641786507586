import { CSS } from '@dnd-kit/utilities';
import { useFormContext } from 'react-hook-form';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useMemo, Dispatch, useState, SetStateAction } from 'react';
import { arrayMove, useSortable, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import {
  useSensor,
  DndContext,
  useSensors,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  UniqueIdentifier,
} from '@dnd-kit/core';

import {
  Box,
  Stack,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';

import { ITestQuestion, SaveTestSectionsYupSchema } from 'src/types/staff/tests';

import TestQuestionsBox from './test-question-box';
import TestCreateEditQuestion from './test-create-edit-question-box';

interface Props {
  section: SaveTestSectionsYupSchema;
  id: string;
  handleEditQuestion: (questionId: string) => void;
  handleCloseEditQuestion: ({ isEditCancelled }: { isEditCancelled?: boolean }) => void;
  handleQuestionSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    question: ITestQuestion,
  ) => void;
  selectedQuestions: ITestQuestion[];
  isEditing: boolean;
  editQuestionId: string;
  setDndItems: Dispatch<SetStateAction<any>>;
}

function TestSectionBox({
  section,
  id,
  handleEditQuestion,
  handleCloseEditQuestion,
  handleQuestionSelect,
  selectedQuestions,
  isEditing,
  editQuestionId,
  setDndItems,
}: Props) {
  const sectionQuestions = section.questions;

  const sensors = useSensors(useSensor(PointerSensor));
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: transform ? 999 : 'auto',
      }
    : undefined;

  const { getValues, setValue } = useFormContext();

  const editSectionDialog = useBoolean(false);
  const [newSectionName, setNewSectionName] = useState(section.name);

  const handleEditDialogOpen = (event: { stopPropagation: () => void }) => {
    setNewSectionName(section.name);
    editSectionDialog.onTrue();
  };

  const handleEditDialogClose = () => {
    editSectionDialog.onFalse();
  };

  const handleSaveEditedSection = () => {
    const sectionIndex = getValues('sections').findIndex(
      (s: { id: string | undefined }) => s.id === section.id,
    );
    setValue(`sections.${sectionIndex}.name`, newSectionName);
    handleEditDialogClose();
  };

  const decrementTotalQuestions = () => {
    const currentTotalQuestions = getValues('totalQuestions');
    setValue('totalQuestions', currentTotalQuestions - section.questions.length);
  };

  const handleRemoveSection = () => {
    const updatedSections = getValues('sections').filter(
      (s: { id: string | undefined }) => s.id !== section.id,
    );
    setValue(`sections`, updatedSections);

    setDndItems((prevItems: any[]) =>
      prevItems.filter((s: { id: string | undefined }) => s.id !== section.id),
    );
    decrementTotalQuestions();
  };

  const items = useMemo(
    () => [
      ...sectionQuestions.map((question) => ({
        id: question._tempId || question.id!,
      })),
    ],
    [sectionQuestions],
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = section.questions.findIndex(
      (question) => question.id === active.id || question._tempId === active.id,
    );
    const newIndex = section.questions.findIndex(
      (question) => question.id === over.id || question._tempId === over.id,
    );

    if (newIndex === -1) return; // If newIndex is not found, exit

    // Reorder the questions within the specific section
    const reorderedQuestions = arrayMove(section.questions, oldIndex, newIndex);

    const updatedQuestions = reorderedQuestions.map((question, index) => ({
      ...question,
      position: index + 1,
    }));

    // Find the index of the section in the sections array
    const sectionIndex = getValues('sections').findIndex(
      (s: { id: string | undefined }) => s.id === section.id,
    );

    // Update the reordered questions in the form state
    setValue(`sections.${sectionIndex}.questions`, updatedQuestions);

    setDndItems(
      (
        prevItems: {
          questions: any;
          id: string | undefined;
        }[],
      ) => {
        const sectionItemIndex = prevItems.findIndex(
          (item: { id: string | undefined }) => section.id === item.id,
        );

        const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
        if (sectionItemIndex !== -1) {
          const oldItemIndex = prevItems[sectionItemIndex].questions.findIndex(
            (question: { id: UniqueIdentifier }) => question.id === active.id,
          );
          const newItemIndex = prevItems[sectionItemIndex].questions.findIndex(
            (question: { id: UniqueIdentifier }) => question.id === over.id,
          );

          const reorderedItems = arrayMove(
            prevItemsCopy[sectionItemIndex].questions,
            oldItemIndex,
            newItemIndex,
          );
          prevItemsCopy[sectionItemIndex].questions = reorderedItems.map((q: any, idx) => ({
            ...q,
            position: idx + 1,
          }));
          return prevItemsCopy;
        }
        return prevItems;
      },
    );
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <Box ref={setNodeRef} style={style} sx={{ backgroundColor: 'white' }}>
            <Stack direction="row" width="100%" alignItems="center">
              <Stack direction="row" alignItems="center">
                <Iconify
                  icon="akar-icons:dot-grid"
                  width="20px"
                  color="grey.600"
                  {...listeners}
                  {...attributes}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    mx: '0.75rem',
                    ml: '1rem',
                    color: 'info.dark',
                    fontWeight: '900',
                    fontSize: '18px',
                    textWrap: 'nowrap',
                  }}
                >
                  {section.name}
                </Typography>
                <Iconify icon="simple-line-icons:frame" width="20px" color="info.dark" />
                <Stack direction="row" alignItems="center" sx={{ ml: '2rem' }} spacing="2px">
                  <IconButton onClick={handleEditDialogOpen}>
                    <Iconify icon="fluent:edit-12-filled" width={20} color="grey.600" />
                  </IconButton>
                  <IconButton onClick={handleRemoveSection}>
                    <Iconify icon="radix-icons:cross-2" width={20} color="grey.600" />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider sx={{ display: 'inline', borderStyle: 'dashed', flexGrow: '1' }} />
            </Stack>

            <Box
              sx={{
                minHeight: '118px',
                backgroundColor: 'grey.100',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '2rem',
                flexDirection: 'column',
                gap: '2rem',
                padding: '2rem',
              }}
            >
              {section.questions.length > 0 ? (
                section.questions.map((question) =>
                  isEditing &&
                  (question._tempId === editQuestionId || question.id === editQuestionId) ? (
                    <TestCreateEditQuestion
                      key={question._tempId || question.id}
                      currentQuestion={question as ITestQuestion}
                      handleCloseEditQuestion={handleCloseEditQuestion}
                      setDndItems={setDndItems}
                    />
                  ) : (
                    <TestQuestionsBox
                      key={question.id || question._tempId}
                      id={question.id || question._tempId!}
                      question={question as ITestQuestion}
                      handleEditQuestion={handleEditQuestion}
                      handleQuestionSelect={handleQuestionSelect}
                      selectedQuestions={selectedQuestions}
                      setDndItems={setDndItems}
                    />
                  ),
                )
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    color: 'grey.500',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  Drag & drop questions inside the section
                </Typography>
              )}
            </Box>
          </Box>
        </SortableContext>
      </DndContext>

      <Dialog
        open={editSectionDialog.value}
        onClose={handleEditDialogClose}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '720px',
          },
        }}
      >
        <DialogTitle sx={{ color: 'info.dark', fontSize: '18px', fontWeight: '900' }}>
          Edit section
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '4px !important' }}>
          <TextField
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <Button color="success" variant="contained" onClick={handleSaveEditedSection}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TestSectionBox;
