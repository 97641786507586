import { useState, useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  useSensor,
  useSensors,
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
} from '@dnd-kit/core';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Paper, TextField, ButtonBase, FormHelperText } from '@mui/material';

import { ANSWERS_TYPES_CONFIG } from 'src/constants/questions';

import Iconify from 'src/components/iconify';

import { IQuestionType, QUESTION_TYPES } from 'src/types/question';
// import { MINIMUM_QUESTIONS_PER_TYPE } from 'src/constants/constants-variables';

import QuestionAnswer from './question-answer';

// ----------------------------------------------------------------------

export default function QuestionAnswersField() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const [newAnswer, setNewAnswer] = useState('');

  const { fields, append, remove, update, swap } = useFieldArray({
    control,
    name: 'answers',
  });

  const [questionTypeFieldVal, answersFieldVal] = watch(['questionType', 'answers']);

  const sensors = useSensors(
    useSensor(PointerSensor),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // }),
  );

  const handleAdd = useCallback(
    (newAnswerTest?: string) => {
      append({
        text: newAnswerTest || '',
        negativePoint: 0,
        positivePoint: 0,
      });
    },
    [append],
  );

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleChangePoints = useCallback(
    (index: number, option: number, field: string) => {
      const otherField = field === 'positivePoint' ? 'negativePoint' : 'positivePoint';
      update(index, {
        ...answersFieldVal[index],
        [field]: Number(option),
        [otherField]: 0,
      });
    },
    [answersFieldVal, update],
  );

  const handleChangeAnswerType = useCallback(
    (answerType: IQuestionType) => {
      const minQuestions = ANSWERS_TYPES_CONFIG[answerType].minimumAnswersRequired;
      let answersCount = answersFieldVal.length;

      // Add questions until minimum requirement is met
      while (answersCount < minQuestions) {
        handleAdd();
        answersCount += 1;
      }

      // Remove extra questions beyond minimum
      if (answersCount > minQuestions) {
        let startingPosition = 0;
        for (let i = answersCount - 1; i >= minQuestions; i -= 1) {
          if (answersFieldVal[i].text === '') {
            remove(i);
          } else {
            let stopIteration = true;
            for (let j = startingPosition; j < i; j += 1) {
              if (answersFieldVal[j].text === '') {
                update(j, {
                  ...answersFieldVal[i],
                });
                remove(i);
                startingPosition = j + 1;
                stopIteration = false;
                break;
              }
            }
            if (stopIteration) break;
          }
        }
      }
    },
    [answersFieldVal, handleAdd, remove, update],
  );

  const isSingleQuestionType = questionTypeFieldVal === 'single';
  const isMultipleQuestionType = questionTypeFieldVal === 'multiple';
  const isTextQuestionType = questionTypeFieldVal === 'text';

  let count = 1;
  if (isSingleQuestionType) count = 2;
  else if (isMultipleQuestionType) count = 3;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = fields.findIndex((item) => item.id === active.id);
      const newIndex = fields.findIndex((item) => item.id === over?.id);
      swap(oldIndex, newIndex);
    }
  };

  return (
    <Stack spacing={3} sx={{ p: 3 }}>
      <Controller
        name="questionType"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Stack>
            <Box gap={2} display="grid" gridTemplateColumns="repeat(3, 1fr)">
              {Object.values(QUESTION_TYPES).map((item) => (
                <Paper
                  component={ButtonBase}
                  variant="outlined"
                  key={item}
                  onClick={() => {
                    handleChangeAnswerType(item);
                    field.onChange(item);
                  }}
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    typography: 'subtitle2',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...(item === field.value && {
                      borderWidth: 2,
                      borderColor: 'text.primary',
                    }),
                    gap: 2,
                  }}
                >
                  <Iconify icon={ANSWERS_TYPES_CONFIG[item].iconifyIcon} width={20} />
                  <Typography variant="subtitle2" fontWeight="fontWeightBold">
                    {ANSWERS_TYPES_CONFIG[item].label}
                  </Typography>
                </Paper>
              ))}
            </Box>
            {error && error.message && (
              <FormHelperText
                color="error"
                error={!!error}
                sx={{ margin: '8px 14px', display: 'flex', alignItems: 'center' }}
              >
                {error.message}
              </FormHelperText>
            )}
          </Stack>
        )}
      />

      {questionTypeFieldVal && (
        <>
          <Stack spacing={3}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                {fields.map((item, index) => (
                  <QuestionAnswer
                    answersFieldVal={fields}
                    key={item.id}
                    id={item.id}
                    index={index}
                    isSingleQuestionType={isSingleQuestionType}
                    isMultipleQuestionType={isMultipleQuestionType}
                    isTextQuestionType={isTextQuestionType}
                    handleChangePoints={handleChangePoints}
                    handleRemove={handleRemove}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="flex-start">
            <TextField
              size="small"
              label="Option"
              name="option"
              type="text"
              value={newAnswer}
              sx={{
                width: 407,
              }}
              onChange={(e) => setNewAnswer(e.target.value)}
              helperText={
                <FormHelperText
                  error={!!errors?.answers?.message}
                  sx={{ display: 'flex', alignItems: 'center', margin: 0, gap: '4px' }}
                >
                  <Iconify icon="material-symbols:info" />
                  {errors?.answers?.message && <>{errors.answers.message}</>}
                  {!errors?.answers?.message &&
                    `At least ${count} options need to be added (included the correct one).`}
                </FormHelperText>
              }
            />
            <Button
              size="medium"
              color="primary"
              variant="contained"
              endIcon={<Iconify icon="mingcute:add-line" />}
              onClick={() => {
                handleAdd(newAnswer);
                setNewAnswer('');
              }}
              sx={{ flexShrink: 0, color: 'primary' }}
            >
              Add Item
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}
