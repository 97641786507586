import { isEqual } from 'lodash';
import { useMemo, useState, useCallback } from 'react';

import { Table, Button, TableBody, Container, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';

import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { TABLE_DEFAULTS } from 'src/constants/misc';
import { useGetCollaboratorsList } from 'src/api/collaborators';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import {
  ICollaboratorsItem,
  ICollaboratorsTableFilters,
  ICollaboratorsTableFilterValue,
} from 'src/types/collaborators';

import CollaboratorsTableRow from './collaborators-table-row';
import CollaboratorsToolbar from '../list/collaborators-toolbar';
import InviteEditCollaboratorsDialog from '../list/invite-edit-collaborators-dialog';
import CollaboratorsTableFiltersResult from '../list/collaborators-table-filters-result';

const defaultFilters: ICollaboratorsTableFilters = {
  role: '',
  searchText: '',
};

const TABLE_HEAD = [
  { id: 'fullName', label: 'Name', width: 241, align: 'left' },
  { id: 'role', label: 'Role', width: 132, align: 'left' },
  { id: 'email', label: 'Email', width: 309, align: 'left' },
  { id: 'premises', label: 'Associated premises', width: 246, align: 'left' },
  { id: 'actions', label: 'Actions', align: 'left' },
  { id: '' },
];

export default function CollaboratorsListView() {
  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });

  const settings = useSettingsContext();

  const [filters, setFilters] = useState(defaultFilters);

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const inviteCollaboratorsDialog = useBoolean();

  const handleFilters = useCallback(
    (name: string, value: ICollaboratorsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            pageSize: table.rowsPerPage,
          }),
      sortBy: table.orderBy === 'fullName' ? 'name' : table.orderBy,
      sortDirection: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<ICollaboratorsTableFilters> = { ...filters };
    if (allFilters.searchText) {
      delete allFilters.searchText;
    }

    return allFilters;
  }, [filters]);

  const [debouncedSearchText] = useDebounce(filters.searchText || '', 1500);

  const apiFilters: IListFilters<ICollaboratorsTableFilters> = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      search: debouncedSearchText,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedSearchText]);

  const { data }: any = useGetCollaboratorsList({ ...apiFilters });

  const {
    collaborators: tableData = [],
    totalCount = 0,
  }: {
    collaborators: ICollaboratorsItem[];
    totalCount: number;
  } = useMemo(
    () => ({
      collaborators: data?.data,
      totalCount:
        data && data?.total_pages > 1
          ? data.total_pages * table.rowsPerPage
          : data?.data.length || 0,
    }),
    [data, table],
  );

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Collaborators"
          links={[{ name: 'Dashboard', href: '#' }, { name: 'collaborators' }]}
          action={
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'customColors.custom1',
              }}
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={inviteCollaboratorsDialog.onTrue}
            >
              Invite collaborators
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <CollaboratorsToolbar onFilters={handleFilters} filters={filters} />

        {canReset && (
          <CollaboratorsTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={totalCount}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                // TODO:
                rowCount={20}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {tableData.length > 0 &&
                  tableData.map((row) => (
                    <CollaboratorsTableRow key={row.id} row={row} headLabel={TABLE_HEAD} />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalCount}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Container>
      <InviteEditCollaboratorsDialog dialog={inviteCollaboratorsDialog} />
    </>
  );
}
