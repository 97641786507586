import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { USER_ROLES } from 'src/constants/user-roles';

export default function CompleteLoginView() {
  const { completeLoginWithEmailLink } = useAuthContext();
  const location = useLocation();
  const router = useRouter();

  useEffect(() => {
    async function checkForEmailLink() {
      try {
        const role = await completeLoginWithEmailLink();
        const redirectRoute = role === USER_ROLES.STUDENT ? paths.root : paths.staff.root;

        router.replace(redirectRoute);
        // TODO: navigate to admin area if user role is admin
      } catch (error) {
        enqueueSnackbar(`Login failed. Please try again`, { variant: 'error' });
        router.replace(paths.login);
      }
    }

    checkForEmailLink();
  }, [location, completeLoginWithEmailLink, router]);

  return (
    <Stack
      sx={{ minHeight: '100vh', backgroundColor: 'primary.main' }}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h3" sx={{ color: 'primary.contrastText' }}>
        Signing you in...
      </Typography>
    </Stack>
  );
}
