import { useState } from 'react';

import { Tab, Tabs, Container } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useSearchParams } from 'src/routes/hooks';

import { TABS } from 'src/constants/categories';
import { useGetCategoryById } from 'src/api/categories';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import CategoryEditViewTests from '../category-edit-view-tests';
import CategoryEditViewGeneral from '../category-edit-view-general';

export default function CategoryEditView({ id }: { id: string }) {
  const settings = useSettingsContext();

  const searchParam = useSearchParams();
  const isNew = searchParam.get('new') === 'true';

  const { data: category, isPending } = useGetCategoryById(id);

  const [currentTab, setCurrentTab] = useState<string>(TABS[isNew ? 1 : 0].name);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Adults"
        links={[
          { name: 'Dashboard', href: '#' },
          { href: paths.staff.test.categories.root, name: 'Categories' },
          { name: category?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Tabs value={currentTab} onChange={(e, newVal) => setCurrentTab(newVal)}>
        {TABS.map((type) => (
          <Tab
            key={type.name}
            sx={{ fontWeight: 'fontWeightBold' }}
            value={type.name}
            iconPosition="start"
            icon={<Iconify icon={type.iconifyIcon} width={24} />}
            label={type.name}
          />
        ))}
      </Tabs>

      {/* General View */}
      {currentTab === 'General' && category && <CategoryEditViewGeneral category={category} />}

      {/* Test View */}
      {currentTab === 'Test' && !isPending && category && category?.tests && (
        <CategoryEditViewTests id={id} tests={category.tests} />
      )}

      {/* Share View */}
      {currentTab === 'Share' && 'Share'}
    </Container>
  );
}
