import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, useFieldArray } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Paper,
  Button,
  Switch,
  Divider,
  Typography,
  FormControlLabel,
} from '@mui/material';

import { fDate } from 'src/utils/format-time';

import keys from 'src/constants/query-keys';
import { createOrUpdateTest } from 'src/api/staff/tests';

import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';

import { ITestDetailsResponse } from 'src/types/staff/tests';

function TestEditGeneralView({ test }: { test: ITestDetailsResponse }) {
  const queryClient = useQueryClient();

  const createOrUpdateTestSchema = Yup.object().shape({
    id: Yup.string().optional(),
    name: Yup.string().required('Test name is required'),
    instruction: Yup.string().required('Instructions is required'),
    isRandomized: Yup.boolean().default(false),
    dimension: Yup.number().optional(),
    categoryId: Yup.string().optional(),
    totalQuestions: Yup.number().required(),
    testDuration: Yup.number().default(3600),
    isPublished: Yup.boolean().required(),
    isArchived: Yup.boolean().required(),
    levels: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().optional(),
          name: Yup.string().required('Level name is required'),
          minPercentage: Yup.number().min(1).max(100).required('Valid percentage is required'),
        }),
      )
      .min(1, 'A level is required'),
  });

  type CreateOrUpdateTest = Yup.InferType<typeof createOrUpdateTestSchema>;

  const defaultValues: CreateOrUpdateTest = useMemo(
    () => ({
      id: test.id,
      name: test.name,
      instruction: test.instruction,
      isRandomized: test.isRandomized,
      totalQuestions: test.totalQuestions,
      testDuration: test.testDuration,
      isPublished: test.isPublished,
      isArchived: test.isArchived,
      createdAt: test.createdAt,
      levels: test.levels,
    }),
    [test],
  );

  const methods = useForm({
    resolver: yupResolver(createOrUpdateTestSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const [newLevelName, setNewLevelName] = useState('');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'levels',
  });
  const handleAddLevel = () => {
    append({ name: newLevelName, minPercentage: 0 });
    setNewLevelName('');
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createOrUpdateTest(data);
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTest, test.id] });
      return enqueueSnackbar('Updated successfully!', { variant: 'success' });
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Paper elevation={3} variant="elevation" square sx={{ p: 3, my: 5 }}>
        <Stack gap={4}>
          <RHFTextField name="name" label="Name" size="small" margin="dense" fullWidth />

          <Box>
            <RHFEditor
              simple
              name="instruction"
              placeholder="Instructions"
              sx={{
                '& .ql-editor': {
                  bgcolor: 'transparent',
                },
              }}
            />
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack spacing={2}>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              Levels*
            </Typography>
            {fields.map((field, index) => (
              <Grid
                key={index}
                container
                gap={2}
                sx={{ m: 0, width: '100% !important' }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sx={{ m: 0, p: '0 !important', display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="akar-icons:dot-grid" width="20px" color="grey.600" />
                </Grid>
                <Grid item xs={8} sx={{ m: 0, p: '0 !important' }}>
                  <RHFTextField
                    name={`levels.${index}.name`}
                    defaultValue={field.name}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1} sx={{ m: 0, p: '0 !important' }}>
                  <RHFTextField
                    name={`levels.${index}.minPercentage`}
                    type="number"
                    label="%"
                    size="small"
                    fullWidth
                    helperText=""
                    ignoreErrorMessage
                  />
                </Grid>
                <Grid item sx={{ m: 0, p: '0 !important' }}>
                  <Button
                    onClick={() => remove(index)}
                    endIcon={<Iconify icon="mdi:close" />}
                    sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            ))}
            {/* <Grid container spacing={3} sx={{ m: 0, width: '100% !important' }}>
              <Grid item xs={12} sm={8} sx={{ m: 0, p: '0 !important' }}> */}
            <Stack gap={2} direction="row" alignItems="start">
              <RHFTextField
                name="levels"
                value={newLevelName}
                onChange={(e) => setNewLevelName(e.target.value)}
                size="small"
                id="level-name-input"
                placeholder="Level"
              />
              <Button
                variant="contained"
                onClick={handleAddLevel}
                disabled={!newLevelName.trim()}
                endIcon={<Iconify icon="mingcute:add-line" />}
                sx={{ color: 'secondary.contrastText', backgroundColor: 'secondary.main' }}
              >
                Add
              </Button>
            </Stack>
            {/* </Grid>
            </Grid> */}
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          {/* <Stack>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              Timer
            </Typography>
            <Controller
              name="testDuration"
              control={control}
              defaultValue={defaultValues.testDuration}
              render={({ field }) => (
                <Select {...field} id="timer-select" sx={{ maxWidth: '188px' }}>
                  {timeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Stack> */}

          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              Status
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={defaultValues.isPublished}
                  color="success"
                  onChange={() => {
                    defaultValues.isPublished = !defaultValues.isPublished;
                  }}
                />
              }
              label="Live"
            />
          </Stack>

          <Stack>
            <Typography
              variant="subtitle2"
              fontWeight="fontWeightBold"
              marginBottom={1}
              sx={{ color: 'info.dark' }}
            >
              Details
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '400', fontSize: '14px', mr: '6px', color: 'grey.600' }}
                >
                  Created on:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '900', fontSize: '14px', color: 'grey.600' }}
                >
                  {fDate(test.createdAt, 'dd/MM/yyyy')}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '400', fontSize: '14px', mr: '6px', color: 'grey.600' }}
                >
                  by:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: '900', fontSize: '14px', color: 'grey.600' }}
                >
                  {`${test.createdBy.name} ${test.createdBy.surname}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack alignItems="end">
            <LoadingButton color="success" type="submit" variant="contained" loading={isSubmitting}>
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </FormProvider>
  );
}

export default TestEditGeneralView;
