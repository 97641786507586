import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import QuestionNewEditForm from '../question-new-edit-form';

export default function QuestionsCreateView() {
  const settings = useSettingsContext();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Questions"
        links={[
          { name: 'Dashboard', href: '#' },
          { name: 'Questions', href: paths.staff.test.questions.root },
          { name: 'Add a new question', href: '#' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <QuestionNewEditForm />
    </Container>
  );
}
