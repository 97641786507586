import { ChangeEvent, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextField, IconButton, InputAdornment } from '@mui/material';

import { ANSWERS_TYPES_CONFIG } from 'src/constants/questions';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import {
  QUESTION_TYPES,
  IQuestionTableFilters,
  IQuestionTableFilterValue,
} from 'src/types/question';

// ----------------------------------------------------------------------

type Props = {
  filters: IQuestionTableFilters;
  onFilters: (name: string, value: IQuestionTableFilterValue) => void;
  //
  pointOptions: {
    value: string;
    label: string;
  }[];
};

export default function QuestionTableToolbar({
  filters,
  onFilters,
  //
  pointOptions,
}: Props) {
  const popover = usePopover();

  //   const [type, setType] = useState<string[]>(filters.types);

  //   const [point, setPoint] = useState<string[]>(filters.points);

  //   const handleChangeType = useCallback((event: SelectChangeEvent<string[]>) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setType(typeof value === 'string' ? value.split(',') : value);
  //   }, []);

  //   const handleChangePoint = useCallback((event: SelectChangeEvent<string[]>) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setPoint(typeof value === 'string' ? value.split(',') : value);
  //   }, []);

  //   const handleCloseType = useCallback(() => {
  //     onFilters('type', type);
  //   }, [onFilters, type]);

  //   const handleClosePoint = useCallback(() => {
  //     onFilters('point', point);
  //   }, [onFilters, point]);

  const handleFilter = useCallback(
    (
      event: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: string,
    ) => {
      onFilters(field, event.target.value);
    },
    [onFilters],
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 287 },
          }}
        >
          <InputLabel id="type-multiple-checkbox-label">Type</InputLabel>

          <Select
            labelId="type-multiple-checkbox-label"
            id="type-multiple-checkbox"
            value={filters.questionType}
            onChange={(e) => handleFilter(e, 'questionType')}
            input={<OutlinedInput label="Type" />}
            sx={{ textTransform: 'capitalize' }}
          >
            {Object.values(QUESTION_TYPES).map((option) => (
              <MenuItem key={option} value={option}>
                {ANSWERS_TYPES_CONFIG[option].label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 287 },
          }}
        >
          <InputLabel id="points-multiple-checkbox-label">Points</InputLabel>

          <Select
            labelId="points-multiple-checkbox-label"
            id="points-multiple-checkbox"
            value={filters.exactPoints}
            onChange={(e) => handleFilter(e, 'exactPoints')}
            input={<OutlinedInput label="Point" />}
          >
            {pointOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.searchText}
            onChange={(e) => handleFilter(e, 'searchText')}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <IconButton onClick={popover.onOpen} disabled>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}
