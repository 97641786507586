import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
// import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Unstable_Grid2';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
// import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

// import { _socials } from 'src/_mock';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

export default function Footer() {
  // const pathname = usePathname();

  // const homePage = pathname === '/';

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'primary.light',
      }}
    >
      <Divider />

      <Container
        sx={{
          paddingX: '0 !important',
          textAlign: { xs: 'center', md: 'unset' },
          maxWidth: 'unset !important',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'primary.dark',
            paddingY: '24px',
            paddingX: '40px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={4}
            alignItems="center"
          >
            <Box component="img" src="/logo/save-the-children.svg" sx={{ width: 57, height: 60 }} />
            <Stack direction="column" spacing={1} width="200px">
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', fontWeight: '800', lineHeight: '19px' }}
              >
                Miglioriamo il nostro futuro
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', fontWeight: '400', lineHeight: '18px' }}
              >
                Collaborazione New English Teaching e Save the Children
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={4}
            alignItems="center"
          >
            <Box component="img" src="/logo/cambridge.svg" sx={{ width: 107, height: 52 }} />
            <Box component="img" src="/logo/ielts.svg" sx={{ width: 62, height: 55 }} />
            <Box component="img" src="/logo/federlingue.svg" sx={{ width: 94, height: 57 }} />
          </Stack>
        </Box>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          alignItems={{
            xs: 'center',
            md: 'flex-start',
          }}
          sx={{
            paddingX: '40px',
            paddingY: '32px',
          }}
          spacing={{
            xs: 6,
            md: 2,
          }}
        >
          <Box sx={{ flexShrink: '1' }}>
            <Logo sx={{ mb: 3 }} />
          </Box>
          <Stack direction="column" spacing={1}>
            <Link
              component={RouterLink}
              href={paths.jobs}
              color="primary.contrastText"
              variant="caption"
              sx={{
                fontWeight: '900',
                letterSpacing: '2px',
                lineHeight: '18px',
              }}
            >
              LAVORA CON NOI
            </Link>
            <Link
              component={RouterLink}
              href={paths.franchising}
              color="primary.contrastText"
              variant="caption"
              sx={{
                fontWeight: '900',
                letterSpacing: '2px',
                lineHeight: '18px',
              }}
            >
              FRANCHISING
            </Link>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Link
              component={RouterLink}
              href={paths.privacyPolicy}
              color="primary.contrastText"
              variant="body1"
              sx={{
                fontWeight: '900',
                letterSpacing: '0.2px',
                lineHeight: '20px',
              }}
            >
              Privacy Policy
            </Link>
            <Link
              component={RouterLink}
              href={paths.cookiePolicy}
              color="primary.contrastText"
              variant="body1"
              sx={{
                fontWeight: '900',
                letterSpacing: '0.2px',
                lineHeight: '20px',
              }}
            >
              Cookie policy
            </Link>
            <Link
              component={RouterLink}
              href={paths.cookieSettings}
              color="primary.contrastText"
              variant="body1"
              sx={{
                fontWeight: '900',
                letterSpacing: '0.2px',
                lineHeight: '20px',
              }}
            >
              Cookie Settings
            </Link>
          </Stack>
          <Stack direction="column" maxWidth="260px">
            <Box>
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', lineHeight: '18px', letterSpacing: '0.2px' }}
              >
                New English Teaching
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', lineHeight: '18px', letterSpacing: '0.2px' }}
              >
                Direzione Generale
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', lineHeight: '18px', letterSpacing: '0.2px' }}
              >
                Via Marconi, Res. Ripa 102 - Milano3
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', lineHeight: '18px', letterSpacing: '0.2px' }}
              >
                20080 Basiglio (MI)
              </Typography>
              <Box sx={{ m: 2 }} />
              <Typography
                variant="body2"
                sx={{ color: 'primary.contrastText', lineHeight: '18px', letterSpacing: '0.2px' }}
              >
                All rights reserved © 1991-2016 — P.I. 10454500157
              </Typography>
            </Box>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Iconify
                icon="lets-icons:phone-fill"
                width={28}
                height={28}
                sx={{ color: 'primary.contrastText' }}
              />
              <Stack direction="column">
                <Link
                  component={RouterLink}
                  href="tel:+39 02 90754901"
                  color="primary.contrastText"
                  variant="body2"
                  sx={{
                    fontWeight: '900',
                    letterSpacing: '0.2px',
                    lineHeight: '18px',
                  }}
                >
                  tel. +39 02 90754901
                </Link>
                <Link
                  component={RouterLink}
                  href="fax:+39 02 90751007"
                  color="primary.contrastText"
                  variant="body2"
                  sx={{
                    fontWeight: '900',
                    letterSpacing: '0.2px',
                    lineHeight: '18px',
                  }}
                >
                  fax +39 02 90751007
                </Link>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Iconify
                icon="foundation:mail"
                width={28}
                height={28}
                sx={{ color: 'primary.contrastText' }}
              />
              <Link
                component={RouterLink}
                href="mailto:info@new-english-teaching.it"
                color="primary.contrastText"
                variant="body2"
                sx={{
                  fontWeight: '900',
                  letterSpacing: '0.2px',
                  lineHeight: '18px',
                }}
              >
                info@new-english-teaching.it
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );

  return mainFooter;
}
