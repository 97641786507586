import { keepPreviousData } from '@tanstack/react-query';

import { useGetData } from 'src/hooks/use-get-data';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';

import { ISettingDetailItem, ITemplateDetailItem } from 'src/types/templates';

export function useGetTemplateList() {
  return useGetData({
    args: [endpoints.staff.notifications.templates],
    queryKey: [queryKeys.staff.notifications.templates.templates],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export function useGetTemplateByRole(role: string) {
  return useGetData({
    args: [endpoints.staff.notifications.templateByRole(role)],
    queryKey: [queryKeys.staff.notifications.templates.templateByRole(role)],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export function useGetTemplateById(id: string) {
  return useGetData({
    args: [`${endpoints.staff.notifications.template}/${id}`],
    queryKey: [queryKeys.staff.notifications.templates.template],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function updateTemplate(data: Partial<ITemplateDetailItem>) {
  const { id, ...payload } = data;
  const URL = `${endpoints.staff.notifications.template}/${id}`;
  await axiosInstance.put(URL, payload);
}

export function useGetSettings() {
  return useGetData({
    args: [`${endpoints.staff.notifications.setting}`],
    queryKey: [queryKeys.staff.notifications.settings.setting],
    options: {
      placeholderData: keepPreviousData,
    },
  });
}

export async function updateSettings(data: Partial<ISettingDetailItem>) {
  const URL = `${endpoints.staff.notifications.setting}`;
  await axiosInstance.put(URL, data);
}
