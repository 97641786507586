import React, { Children } from 'react';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  Box,
  Stack,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import queryKeys from 'src/constants/query-keys';
import { deleteStudent } from 'src/api/students';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { ITableColumns } from 'src/types/misc';
import { IStudentModifiedItem } from 'src/types/students';

type Props = {
  row: IStudentModifiedItem;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function StudentTableRow({ row, headLabel }: Props) {
  const confirm = useBoolean();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleDeleteStudent = async () => {
    try {
      confirm.onFalse();
      await deleteStudent(row.id);
      enqueueSnackbar(`Student Deleted`);
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.students.students] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleEdit = async () => {
    navigate(`${row.id}`);
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title="Edit student" placement="top" arrow>
        <IconButton onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title="Delete student" placement="top" arrow>
        <IconButton onClick={() => confirm.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.field_mod) {
              return (
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                  }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.field_mod]}
                </TableCell>
              );
            }
            if (headCell?.id) {
              if (headCell.id === 'email') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                      color: 'customColors.custom1',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* <Link> */}
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                    {/* </Link> */}
                  </TableCell>
                );
              }

              if (headCell.id === 'level') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {row.level && (
                      <Label
                        variant="soft"
                        sx={{
                          backgroundColor: 'secondary.lighter',
                          color: 'secondary.contrastText',
                          fontWeight: '900',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                      >
                        {row.level}
                      </Label>
                    )}
                  </TableCell>
                );
              }

              if (headCell.id === 'phone') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      color: 'text.secondary',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                  </TableCell>
                );
              }

              if (headCell.id === 'premises') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id].length ? (
                      <Stack direction="row" spacing={2}>
                        <Box width={16}>
                          <Iconify
                            color="secondary.lighter"
                            icon="fluent:location-24-filled"
                            width={16}
                            height={16}
                          />
                        </Box>
                        <Typography variant="body2" whiteSpace="wrap">
                          {/* @ts-ignore */}
                          {row?.premises.join(', ')}
                        </Typography>
                      </Stack>
                    ) : (
                      ''
                    )}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <>{tableActions}</>;
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.id]}
                </TableCell>
              );
            }
            return <>{tableDeleteAction}</>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              Delete student profile?
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400}>
            Are you sure you want to delete this student profile?
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteStudent}>
            Delete
          </Button>
        }
      />
    </>
  );
}
