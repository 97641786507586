import v from 'voca';
import React from 'react';

import { Container } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useGetTemplateById } from 'src/api/templates';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { ITemplateDetailItem } from 'src/types/templates';

import TemplateEditForm from '../edit/template-edit-form';

type Props = {
  id: string;
  role: string;
};

export default function TemplateEditView({ id, role }: Props) {
  const settings = useSettingsContext();
  const { data }: any = useGetTemplateById(id);

  const templateDetails: ITemplateDetailItem = React.useMemo(() => data || {}, [data]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={v.titleCase(templateDetails.name) || 'Student'}
        links={[
          { name: 'Dashboard', href: '#' },
          { name: 'Notification', href: paths.staff.notifications.email },
          {
            name: v.titleCase(role || 'Student'),
            href: paths.staff.notifications.getEmailTemplates(role),
          },
          {
            name: v.titleCase(templateDetails.name || 'Test Completed'),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <TemplateEditForm templateDetails={templateDetails} />
    </Container>
  );
}
