import React from 'react';

import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends ButtonProps {
  customvariant: 'contact' | 'signin';
}

const StyledButton = styled(Button)<{ customvariant: 'contact' | 'signin' }>(
  ({ theme, customvariant }) => ({
    ...(customvariant === 'contact' && {
      borderRadius: '24px',
      padding: '18px 46px',
      fontSize: '14px',
      lineHeight: '14px',
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    }),
    ...(customvariant === 'signin' && {
      minHeight: '64px',
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '900',
      letterSpacing: '0.2px',
      backgroundColor: '#ffffff',
      borderRadius: '16px',
      boxShadow: theme.customShadows.button,
      color: theme.palette.grey[900],
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    }),
    textWrap: 'nowrap',
  }),
);

const CustomButton: React.FC<CustomButtonProps> = ({ customvariant, children, ...props }) => (
  <StyledButton {...props} customvariant={customvariant}>
    {children}
  </StyledButton>
);

export default CustomButton;
