import axios from 'axios';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useMemo, useState, Dispatch, useEffect, useCallback, SetStateAction } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { isQuestionOrSection } from 'src/utils/misc';

import { _tags } from 'src/_mock';
import { createFileUploadLink } from 'src/api/utils';
import { QUESTION_MEDIA_TYPES } from 'src/constants/questions';
import { NewQuestionSchema } from 'src/schemas/new-edit-question';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import {
  RHFEditor,
  RHFTextField,
  RHFAutocomplete,
  RHFUploadBoxCustom,
} from 'src/components/hook-form';

import { ITestQuestion } from 'src/types/staff/tests';
import { IQuestionItem, IQuestionType, IQuestionAnswer } from 'src/types/question';

import AnswersFields from '../questions/question-answers-fields';

// ----------------------------------------------------------------------

type Props = {
  currentQuestion: ITestQuestion;
  handleCloseEditQuestion: ({ isEditCancelled }: { isEditCancelled?: boolean }) => void;
  setDndItems: Dispatch<SetStateAction<any>>;
};

export default function TestCreateEditQuestion({
  currentQuestion,
  handleCloseEditQuestion,
  setDndItems,
}: Props) {
  const id = currentQuestion.id || currentQuestion._tempId!;
  const { sectionId } = currentQuestion;

  const parentMethods = useFormContext();
  const { setValue: setParentValue, getValues: getParentValues } = parentMethods;

  const defaultValues = useMemo(
    () => ({
      ...(currentQuestion || {}),
      question: currentQuestion?.question || '',
      questionType: currentQuestion?.questionType || null,
      answers:
        currentQuestion?.answers?.map((answer) => ({
          text: answer.text || '',
          positivePoint: (answer.points > 0 && answer.points) || 0,
          negativePoint: (answer.points < 0 && answer.points) || 0,
        })) || [],
      isPublished: currentQuestion?.isPublished ?? true,
      //
      instruction: currentQuestion?.instruction || '',
      mediaPath:
        currentQuestion?.imagePath ||
        currentQuestion?.audioPath ||
        currentQuestion?.videoPath ||
        null,
      mimeType: currentQuestion?.mimeType || undefined,
      tags: currentQuestion?.tags || [],
      questionId: null,
      isNewQuestion: currentQuestion?.isNewQuestion || false,
    }),
    [currentQuestion],
  );

  const methods = useForm({
    resolver: yupResolver(NewQuestionSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
      }
    : undefined;

  const { enqueueSnackbar } = useSnackbar();

  const [editingQuestionMediaTab, setEditingQuestionMediaTab] = useState('');

  useEffect(() => {
    if (currentQuestion) {
      if (currentQuestion.mimeType) {
        let currMedia = '';
        Object.entries(QUESTION_MEDIA_TYPES).find(([mediaType, config]) => {
          if (currentQuestion.mimeType! in config.accept) {
            currMedia = mediaType;
            return true;
          }
          return false;
        });
        setEditingQuestionMediaTab(currMedia);
        setCurrentTab(currMedia);
      }
    }
  }, [currentQuestion]);

  const handleImageUpload = async (media: any, mimeType: string) => {
    try {
      if (media) {
        const {
          data: { data: presignedPostData },
        } = await createFileUploadLink({
          mimeType,
          itemType: currentTab,
        });

        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('file', media);
        await axios({
          method: 'post',
          url: presignedPostData.url,
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data;`,
          },
        });

        return presignedPostData.fields.key;
      }
      return '';
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      return '';
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('mediaPath', newFile, { shouldValidate: true });
        setValue('mimeType', newFile.type);
      }
    },
    [setValue],
  );

  const handleRemoveFile = useCallback(() => {
    setValue('mediaPath', null);
  }, [setValue]);

  const [currentTab, setCurrentTab] = useState(Object.keys(QUESTION_MEDIA_TYPES)[0]);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
      handleRemoveFile();
    },
    [handleRemoveFile],
  );

  const handleSaveQuestion = handleSubmit(async (data) => {
    const { answers, questionType, mediaPath, tags, mimeType, ...restFormData } = data;

    let mediaAndMimeType = {};

    if ('isNewQuestion' in restFormData) {
      delete restFormData.isNewQuestion;
    }

    if (mediaPath) {
      const uploadPath = mediaPath?.preview
        ? await handleImageUpload(mediaPath, mimeType as string)
        : mediaPath;

      mediaAndMimeType = {
        ...(editingQuestionMediaTab && {
          [QUESTION_MEDIA_TYPES[editingQuestionMediaTab]?.dbField]: null,
        }),
        [QUESTION_MEDIA_TYPES[currentTab].dbField]: uploadPath,
        mimeType,
      };
    } else if (editingQuestionMediaTab) {
      mediaAndMimeType = {
        [QUESTION_MEDIA_TYPES[editingQuestionMediaTab]?.dbField]: null,
        mimeType: null,
      };
    }

    let totalPoints: number = 0;
    const modifiedAnswersObject: IQuestionAnswer[] = answers!.map((answer) => {
      const { text, negativePoint, positivePoint } = answer;
      if (questionType === 'multiple') {
        totalPoints += +positivePoint;
      } else if (totalPoints < positivePoint) {
        totalPoints = +positivePoint;
      }
      return {
        text,
        points: positivePoint || negativePoint,
      };
    });

    const finalData: IQuestionItem = {
      ...restFormData,
      questionType: questionType as IQuestionType,
      answers: modifiedAnswersObject,
      tags: tags as string[],
      totalPoints,
      videoPath: null,
      ...mediaAndMimeType,
    };

    setDndItems((prevItems: any) => {
      const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
      prevItemsCopy?.some((item: Record<string, any>, i: string | number) => {
        if (
          item.id === finalData.id ||
          (item._tempId === finalData.id && isQuestionOrSection(item))
        ) {
          prevItemsCopy[i] = finalData;
          return true;
        }

        if (item?.questions) {
          item?.questions?.some(
            (q: { id: string | undefined; _tempId: string | undefined }, j: string | number) => {
              if (q.id === finalData.id || q._tempId === finalData.id) {
                prevItemsCopy[i].questions[j] = finalData;
                return true;
              }
              return false;
            },
          );
        }
        return false;
      });
      return prevItemsCopy;
    });

    const independentQuestions = getParentValues('independentQuestions');
    let found = false;
    const updatedIndependentQuestions = independentQuestions.map(
      (question: { id: string; _tempId: string }) => {
        if (question.id === id || question._tempId === id) {
          found = true;
          return finalData;
        }
        return question;
      },
    );

    if (found) {
      setParentValue('independentQuestions', updatedIndependentQuestions);
    } else {
      // If not found in independentQuestions, search in sections
      const sections = getParentValues('sections');
      const sectionIndex = sections.findIndex(
        (section: { id: string }) => section.id === sectionId,
      );

      if (sectionIndex !== -1) {
        const section = sections[sectionIndex];
        const questionIndex = section.questions.findIndex(
          (question: { id: string; _tempId: string }) =>
            question.id === id || question._tempId === id,
        );

        if (questionIndex !== -1) {
          const updatedQuestions = [...section.questions];
          updatedQuestions[questionIndex] = finalData;
          const updatedSection = {
            ...section,
            questions: updatedQuestions,
          };

          const updatedSections = [...sections];
          updatedSections[sectionIndex] = updatedSection;
          setParentValue('sections', updatedSections);
        }
      }
    }

    handleCloseEditQuestion({});
  });

  const handleCancelClick = () => {
    reset();
    handleCloseEditQuestion({ isEditCancelled: true });
  };

  const renderDetails = (
    <Stack spacing={2}>
      <Typography
        variant="body1"
        sx={{ fontWeight: '900', lineHeight: '24px', color: 'info.dark' }}
      >
        {`Question's content`}
      </Typography>

      <Stack spacing={3} direction="row">
        <Stack spacing={3} width="100%">
          <RHFTextField name="question" label="Question" />

          <RHFEditor
            simple
            name="instruction"
            placeholder="Instructions for the question"
            sx={{
              '& .ql-editor': {
                bgcolor: 'transparent',
              },
            }}
          />
        </Stack>

        <Stack spacing={1} width="90%">
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
            }}
          >
            {Object.keys(QUESTION_MEDIA_TYPES).map((type) => (
              <Tab
                key={type}
                sx={{ flexGrow: 1, fontWeight: 'fontWeightBold' }}
                value={type}
                iconPosition="end"
                icon={<Iconify icon={QUESTION_MEDIA_TYPES[type].iconifyIcon} width={24} />}
                label={QUESTION_MEDIA_TYPES[type].label}
              />
            ))}
          </Tabs>

          <RHFUploadBoxCustom
            thumbnail
            acceptingType={QUESTION_MEDIA_TYPES[currentTab].accept}
            name="mediaPath"
            onDrop={handleDrop}
            onDelete={handleRemoveFile}
            mimeType={currentQuestion.mimeType}
          />
        </Stack>
      </Stack>
    </Stack>
  );

  const renderAnswerTypes = (
    <Stack>
      <Typography
        variant="body1"
        sx={{ fontWeight: '900', lineHeight: '24px', color: 'info.dark' }}
      >
        {`Answer's typology`}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: '400', lineHeight: '22px', color: 'grey.600' }}>
        Select the answer typology
      </Typography>
      <AnswersFields />
    </Stack>
  );

  const renderTags = (
    <Stack>
      <RHFAutocomplete
        name="tags"
        label="Tags"
        placeholder="+ Tags"
        multiple
        freeSolo
        options={_tags.map((option) => option)}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {option}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              label={option}
              size="small"
              color="info"
              variant="soft"
            />
          ))
        }
      />
    </Stack>
  );

  const renderActions = (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Button
        size="large"
        color="inherit"
        variant="outlined"
        sx={{ alignSelf: 'center' }}
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
      {/* <Button variant="contained" size="large" onClick={handleSaveQuestion}> */}
      <LoadingButton
        type="submit"
        variant="contained"
        size="large"
        loading={isSubmitting}
        onClick={handleSaveQuestion}
      >
        Save
        {/* </Button> */}
      </LoadingButton>
    </Stack>
  );

  return (
    <Stack ref={setNodeRef} style={style} alignItems="center" direction="row" width="100%">
      <FormProvider {...methods}>
        <Stack alignItems="center" direction="row">
          <Iconify
            icon="akar-icons:dot-grid"
            width="20px"
            color="grey.600"
            {...listeners}
            {...attributes}
            sx={{
              cursor: 'pointer',
            }}
          />
          <Typography
            variant="body2"
            sx={{ mx: '0.75rem', color: 'grey.600', fontWeight: '900', fontSize: '16px' }}
          >
            {currentQuestion.position}
          </Typography>
        </Stack>
        <Card
          sx={{ p: '1.5rem', display: 'flex', gap: '2rem', flexDirection: 'column', width: '100%' }}
        >
          {renderDetails}

          {renderAnswerTypes}

          {renderTags}

          {renderActions}
        </Card>
      </FormProvider>
    </Stack>
  );
}
