import axios from 'axios';
import * as yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Button, Typography, CardContent, ListItemText } from '@mui/material';

import queryKeys from 'src/constants/query-keys';
import { updateSettings } from 'src/api/templates';
import { createFileUploadLink } from 'src/api/utils';
import { SETTINGS_COLOR_PICKERS } from 'src/constants/template';
import { MIME_TYPES, FILE_UPLOAD_PATHS } from 'src/constants/misc';

import Iconify from 'src/components/iconify';
import { RHFUpload } from 'src/components/hook-form';
import { ColorPicker } from 'src/components/color-utils';
import FormProvider from 'src/components/hook-form/form-provider';

import { ISettingDetailItem } from 'src/types/templates';

type Props = {
  settingDetails: ISettingDetailItem;
};

export default function SettingEditForm({ settingDetails }: Props) {
  const [image, setImage] = React.useState<File | undefined>();
  const queryClient = useQueryClient();

  const templateEditFormSchema = yup.object().shape({
    logoPath: yup.mixed(),
    titleColor: yup.string(),
    paragraphColor: yup.string(),
    linkColor: yup.string(),
    buttonColor: yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      logoPath: settingDetails.logoPath || null,
      titleColor: settingDetails.titleColor || SETTINGS_COLOR_PICKERS.titleColor[0],
      paragraphColor: settingDetails.paragraphColor || SETTINGS_COLOR_PICKERS.paragraphColor[0],
      linkColor: settingDetails.linkColor || SETTINGS_COLOR_PICKERS.linkColor[0],
      buttonColor: settingDetails.buttonColor || SETTINGS_COLOR_PICKERS.buttonColor[0],
    }),
    [settingDetails],
  );

  const methods = useForm({
    resolver: yupResolver(templateEditFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
    control,
  } = methods;

  const handleImageUpload = async () => {
    try {
      if (image) {
        const {
          data: { data: presignedPostData },
        } = await createFileUploadLink({
          mimeType: MIME_TYPES.jpg,
          itemType: FILE_UPLOAD_PATHS.image,
        });

        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('file', image);
        await axios({
          method: 'post',
          url: presignedPostData.url,
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data;`,
          },
        });

        setImage(undefined);
        return presignedPostData.fields.key;
      }
      return '';
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
      setImage(undefined);
      return '';
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const uploadPath = await handleImageUpload();
      await updateSettings({ ...data, logoPath: uploadPath || data.logoPath });

      queryClient.invalidateQueries({
        queryKey: [queryKeys.staff.notifications.settings.setting],
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      enqueueSnackbar('Update success!');
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        reset(defaultValues);
      } else {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  });

  const handleReset = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (settingDetails) {
      handleReset();
    }
  }, [settingDetails, reset, defaultValues, handleReset]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setImage(file);

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('logoPath', newFile, { shouldValidate: true });
      }
    },
    [setValue],
  );

  const handleRemoveFile = useCallback(() => {
    setImage(undefined);
    setValue('logoPath', null);
  }, [setValue]);

  const renderText = (
    <ListItemText
      primary="Style"
      secondary="Logo and colors for emails"
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
        fontWeight: 900,
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'body2',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );

  const action = (
    <Stack direction="row" spacing={1.5} justifyContent="end">
      <LoadingButton
        type="submit"
        variant="contained"
        color="success"
        size="medium"
        loading={isSubmitting}
      >
        Save
      </LoadingButton>
      <Button variant="outlined" size="medium" onClick={handleReset}>
        Cancel
      </Button>
    </Stack>
  );

  const uploadLogoPlaceholder = (
    <Stack
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      sx={{ color: 'text.disabled' }}
    >
      <Iconify icon="eva:cloud-upload-fill" height={40} width={40} />
      <Typography variant="body2" textAlign="center">
        Upload file
      </Typography>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 2fr',
          },
          gap: 3,
          width: '100%',
        }}
      >
        <Stack>{renderText}</Stack>
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <Typography variant="subtitle2" fontWeight={900}>
                  Logo
                </Typography>
                <Stack spacing={3} alignItems="center">
                  <Stack minHeight={106} width="100%">
                    <RHFUpload
                      name="logoPath"
                      maxSize={3145728}
                      uploadLogoPlaceholder={uploadLogoPlaceholder}
                      onDrop={handleDrop}
                      onDelete={handleRemoveFile}
                    />
                  </Stack>
                  <Stack sx={{ color: 'text.disabled' }}>
                    <Typography variant="caption" textAlign="center">
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                    </Typography>
                    <Typography variant="caption" textAlign="center">
                      Max size of 3.1 MB
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={3}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={900}>
                      Title color
                    </Typography>
                    <Controller
                      name="titleColor"
                      control={control}
                      render={({ field }) => (
                        <ColorPicker
                          colors={SETTINGS_COLOR_PICKERS.titleColor}
                          selected={field.value ?? SETTINGS_COLOR_PICKERS.titleColor[0]}
                          onSelectColor={(color) => field.onChange(color as string)}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={900}>
                      Paragraph color
                    </Typography>
                    <Controller
                      name="paragraphColor"
                      control={control}
                      render={({ field }) => (
                        <ColorPicker
                          colors={SETTINGS_COLOR_PICKERS.paragraphColor}
                          selected={field.value ?? SETTINGS_COLOR_PICKERS.paragraphColor[0]}
                          onSelectColor={(color) => field.onChange(color as string)}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={900}>
                      Link color
                    </Typography>
                    <Controller
                      name="linkColor"
                      control={control}
                      render={({ field }) => (
                        <ColorPicker
                          colors={SETTINGS_COLOR_PICKERS.linkColor}
                          selected={field.value ?? SETTINGS_COLOR_PICKERS.linkColor[0]}
                          onSelectColor={(color) => field.onChange(color as string)}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight={900}>
                      Button color
                    </Typography>
                    <Controller
                      name="buttonColor"
                      control={control}
                      render={({ field }) => (
                        <ColorPicker
                          colors={SETTINGS_COLOR_PICKERS.buttonColor}
                          selected={field.value ?? SETTINGS_COLOR_PICKERS.buttonColor[0]}
                          onSelectColor={(color) => field.onChange(color as string)}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          {action}
        </Stack>
      </Box>
    </FormProvider>
  );
}
