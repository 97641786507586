import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link, Switch, Button, FormControlLabel } from '@mui/material';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import keys from 'src/constants/query-keys';
import { archiveCategory } from 'src/api/categories';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomShareDialog from 'src/components/custom-share-dialog/custom-share-dialog';

import { ICategoryList } from 'src/types/category';

// ----------------------------------------------------------------------

type Props = {
  category: ICategoryList;
  handleToggleActive: ({
    id,
    field,
    status,
  }: {
    id: string;
    field: string;
    status: boolean;
  }) => void;
};

export default function CategoryCard({ category, handleToggleActive }: Props) {
  const { id, name, color, icon, totalTestCount, isActive } = category;

  const queryClient = useQueryClient();

  const confirm = useBoolean();
  const shareDialog = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteCategory = async () => {
    confirm.onFalse();
    try {
      await archiveCategory(id!);
      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchAllCategories] });
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.message;
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  };

  const redirectUrl = paths.staff.test.categories.editView(id!);

  return (
    <>
      <Card>
        <Box padding={3} gap={2} display="flex" alignItems="center">
          <Avatar alt={name} sx={{ width: 48, height: 48, bgcolor: color }}>
            {icon ? <Iconify icon={icon} width={24} /> : name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography
            sx={{
              flexGrow: 1,
              textTransform: 'capitalize',
            }}
            fontWeight="bold"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            variant="subtitle1"
          >
            <Link
              color="inherit"
              href={redirectUrl}
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ cursor: 'pointer' }}
            >
              {name}
            </Link>
          </Typography>
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Switch
                onChange={(e) => {
                  handleToggleActive({ id: id!, field: name, status: !isActive });
                }}
                color="success"
                checked={isActive}
              />
            }
            label="Show"
          />
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box padding={3} gap={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            gap="4px"
            alignItems="center"
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify width="16" height="16" icon="solar:notes-bold" />
            <Typography variant="body1" fontSize="14px">
              {totalTestCount} tests
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton LinkComponent={RouterLink} href={redirectUrl}>
              <Iconify icon="eva:external-link-outline" />
            </IconButton>
            <IconButton onClick={shareDialog.onTrue}>
              <Iconify icon="solar:share-bold" />
            </IconButton>
            <IconButton color="error" onClick={confirm.onTrue}>
              <Iconify icon="solar:trash-bin-trash-bold" color="red" />
            </IconButton>
          </Box>
        </Box>
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" />{' '}
            <Typography variant="h6" fontWeight="bold">
              Delete Category?
            </Typography>
          </Box>
        }
        content={
          <>
            <Typography variant="subtitle1" fontWeight="bold" color="grey">
              Are you sure you want to delete this category?
            </Typography>
            <Typography variant="body1" fontSize="16px" color="grey">
              All the tests that belong to it will still be available in the library.
            </Typography>
          </>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteCategory}>
            Delete
          </Button>
        }
      />

      <CustomShareDialog shareDialog={shareDialog} />
    </>
  );
}
