import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  useSensor,
  DndContext,
  useSensors,
  DragEndEvent,
  closestCenter,
  PointerSensor,
} from '@dnd-kit/core';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import uuidv4 from 'src/utils/uuidv4';
import { isQuestionOrSection } from 'src/utils/misc';

import keys from 'src/constants/query-keys';
import { saveTestQuestions } from 'src/api/staff/tests';

import Iconify from 'src/components/iconify/iconify';
import { enqueueSnackbar } from 'src/components/snackbar';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSwitch, RHFTextField } from 'src/components/hook-form';

import {
  ITestSection,
  ITestQuestion,
  saveTestYupSchema,
  SaveTestSectionsYupSchema,
  SaveTestQuestionsYupSchema,
} from 'src/types/staff/tests';

import TestSectionBox from './test-section-box';
import TestQuestionsBox from './test-question-box';
import NoQuestionsAdded from './test-no-questions';
import QuestionSearchBox from './test-questions-search-box';
import TestSectionsDropdown from './test-sections-dropdown';
import TestCreateEditQuestion from './test-create-edit-question-box';

function TestEditQuestionsView({
  testId,
  testQuestions,
  sections,
  isRandomized,
  dimensions,
  totalQuestions,
}: {
  testId: string;
  testQuestions: ITestQuestion[];
  sections: ITestSection[];
  isRandomized: boolean;
  dimensions: number | null;
  totalQuestions: number;
}) {
  const queryClient = useQueryClient();
  const sensors = useSensors(useSensor(PointerSensor));

  const newSectionDialog = useBoolean(false);
  const [newSectionName, setNewSectionName] = useState('');

  const [editQuestionId, setEditQuestionId] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState<ITestQuestion[]>([]);
  const [questionTargetLocationId, setQuestionTargetLocationId] = useState('');

  const defaultValues: SaveTestQuestionsYupSchema = useMemo(() => {
    const sortedSections = sections.map((section) => ({
      ...section,
      testId,
      questions: testQuestions
        .filter((question) => question.sectionId === section.id)
        .sort((a, b) => a.position - b.position),
    }));

    const independentQuestions = testQuestions.filter((question) => !question.sectionId);

    return {
      independentQuestions,
      sections: sortedSections,
      isRandomized,
      dimension: dimensions,
      totalQuestions,
    };
  }, [dimensions, isRandomized, sections, testId, testQuestions, totalQuestions]);

  const [dndItems, setDndItems] = useState(
    [...defaultValues.independentQuestions, ...defaultValues.sections!].sort(
      (a, b) => a.position - b.position,
    ),
  );

  const { itemSections } = useMemo(() => {
    const independentQuestionsArr = dndItems.filter(
      (item) => isQuestionOrSection(item) === 'question',
    );
    const sectionsArr = dndItems.filter((item) => isQuestionOrSection(item) === 'section');
    return {
      itemIndependentQuestions: independentQuestionsArr,
      itemSections: sectionsArr,
    };
  }, [dndItems]);

  const handleNewSectionDialogOpen = () => {
    newSectionDialog.onTrue();
  };

  const handleDialogClose = () => {
    newSectionDialog.onFalse();
  };

  const handleCreateSection = () => {
    if (!newSectionName.trim()) {
      enqueueSnackbar('Section name cannot be empty', { variant: 'error' });
      return;
    }
    const newSection = {
      name: newSectionName,
      questions: [],
      position: independentQuestions.length + sections.length + 1,
      instruction: '',
      testId,
      _tempId: uuidv4(),
    };

    const newSectionItem = {
      ...newSection,
      id: newSection._tempId,
    };

    const existingSections = methods.getValues('sections') || [];
    methods.setValue('sections', [...existingSections, newSection]);
    setDndItems([newSectionItem, ...dndItems]);
    setNewSectionName('');
    handleDialogClose();
  };

  const methods = useForm({
    resolver: yupResolver(saveTestYupSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    // if (currentDimensions && currentTotalQuestions > currentDimensions) {
    //   return enqueueSnackbar('Save failed! Questions exceeding specified dimensions', {
    //     variant: 'error',
    //   });
    // }

    try {
      await saveTestQuestions(testId, data);
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTest, testId] });
      queryClient.invalidateQueries({ queryKey: [keys.staff.tests.fetchTests] });
      return enqueueSnackbar('Saved questions successfully!', { variant: 'success' });
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      return enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const handleSearchQuestionChoice = (question: ITestQuestion) => {
    // if (currentDimensions && currentTotalQuestions >= currentDimensions) {
    //   enqueueSnackbar('Unable to add new question. Exceeding dimensions', { variant: 'error' });
    //   return;
    // }

    const { id, ...questionInfo } = question;

    const formattedQuestion = {
      ...questionInfo,
      _tempId: uuidv4(),
      questionId: id,
      testId,
    };

    const formattedItem = {
      ...formattedQuestion,
      id: formattedQuestion._tempId,
    };

    const updatedFormQuestions = [...independentQuestions, formattedQuestion].map((q, index) => ({
      ...q,
      position: index + 1,
    }));

    const updatedDndItems = [...dndItems, formattedItem].map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    methods.setValue('independentQuestions', updatedFormQuestions);
    setDndItems(updatedDndItems);
  };

  const independentQuestions = watch('independentQuestions');
  const sectionsArr = watch('sections');
  // const currentTotalQuestions = watch('totalQuestions');
  // const currentDimensions = watch('dimension');

  const updateTotalQuestions = useCallback(() => {
    const independentQuestionsCount = independentQuestions.length;
    const sectionQuestionsCount = sectionsArr!.reduce(
      (total, section) => total + (section.questions ? section.questions.length : 0),
      0,
    );

    const sumOfQuestions = independentQuestionsCount + sectionQuestionsCount;

    methods.setValue('totalQuestions', sumOfQuestions);
  }, [independentQuestions, sectionsArr, methods]);

  useEffect(() => {
    updateTotalQuestions();
  }, [updateTotalQuestions]);

  const excludeIdsFromSearch = useMemo(() => {
    const independentQuestionIds = independentQuestions.map((question) => question.questionId!);
    const sectionQuestionIds = (sectionsArr || []).flatMap((section) =>
      (section.questions || []).map((question) => question.questionId!),
    );
    return [...independentQuestionIds, ...sectionQuestionIds].filter((id) => id);
  }, [independentQuestions, sectionsArr]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId !== overId) {
      const activeItemIndex = dndItems.findIndex((item) => item.id === activeId);
      const overItemIndex = dndItems.findIndex((item) => item.id === overId);

      const reorderedSectionsAndQuestions = arrayMove(dndItems, activeItemIndex, overItemIndex);
      const updatedSectionsAndQuestions = reorderedSectionsAndQuestions.map(
        (sectionOrQuestion, index) => ({
          ...sectionOrQuestion,
          position: index + 1,
        }),
      );

      setDndItems(updatedSectionsAndQuestions);

      methods.setValue(
        'independentQuestions',
        updatedSectionsAndQuestions
          .filter((currentItem) => isQuestionOrSection(currentItem) === 'question')
          .map((q) => {
            if (q._tempId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = q;
              return rest;
            }
            return q;
          }) as any,
      );

      methods.setValue(
        'sections',
        updatedSectionsAndQuestions
          .filter((currentItem) => isQuestionOrSection(currentItem) === 'section')
          .map((s) => {
            if (s._tempId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = s;
              return rest;
            }
            return s;
          }) as any,
      );
    }
  };

  const handleAddNewQuestion = () => {
    if (isEditing) return;
    // if (currentDimensions && currentTotalQuestions >= currentDimensions) {
    //   enqueueSnackbar('Unable to add new question. Exceeding dimensions', { variant: 'error' });
    //   return;
    // }

    const newQuestion = {
      _tempId: uuidv4(),
      question: 'New Question',
      answers: [
        {
          text: 'Option 1',
          points: 5,
        },
        {
          text: 'Option 2',
          points: 0,
        },
      ],
      totalPoints: 5,
      questionId: '',
      instruction: 'Instructions',
      position: 0,
      sectionId: null,
      testId,
      tags: [],
      isPublished: true,
      questionType: 'single' as 'single' | 'multiple' | 'text',
      isUpdatedOnQuestionsTable: false,
      isNewQuestion: true,
    };

    const newDndItem = {
      ...newQuestion,
      id: newQuestion._tempId,
    };

    const updatedFormQuestions = [...independentQuestions, newQuestion].map((question, index) => ({
      ...question,
      position: index + 1,
    }));
    const updatedDndItems = [...dndItems, newDndItem].map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    methods.setValue('independentQuestions', updatedFormQuestions);
    setDndItems(updatedDndItems);
    setEditQuestionId(newQuestion._tempId);
    setIsEditing(true);
  };

  const handleEditQuestion = (questionId: string) => {
    setEditQuestionId(questionId);
    setIsEditing(true);
  };

  const handleCloseEditQuestion = ({ isEditCancelled = false }: { isEditCancelled?: boolean }) => {
    if (editQuestionId && isEditCancelled) {
      // Check if the question is in independentQuestions
      const independentIndex = independentQuestions.findIndex(
        (q) => 'isNewQuestion' in q && q._tempId === editQuestionId,
      );
      if (independentIndex !== -1) {
        // Remove from independentQuestions
        const updatedIndependents = [
          ...independentQuestions.slice(0, independentIndex),
          ...independentQuestions.slice(independentIndex + 1),
        ];
        methods.setValue('independentQuestions', updatedIndependents);

        setDndItems((prevItems: any) => {
          const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
          return prevItemsCopy.filter(
            (item: { _tempId: string }) => item?._tempId !== editQuestionId,
          );
        });
      }
    }
    setEditQuestionId('');
    setIsEditing(false);
  };

  const handleQuestionSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    question: ITestQuestion,
  ) => {
    if (event.target.checked) {
      setSelectedQuestions((curSelectedQuestions) => [...curSelectedQuestions, question]);
    } else {
      setSelectedQuestions((curSelectedQuestions) =>
        curSelectedQuestions.filter((q) => q.id !== question.id),
      );
    }
  };

  const handleMoveQuestions = () => {
    if (selectedQuestions.length === 0) return;

    let newItems;
    const itemsCopy = JSON.parse(JSON.stringify(dndItems));
    const targetLocation = questionTargetLocationId || null; // null for independent questions

    // Filter out questions that aren't in the target location already
    const filteredQuestions = selectedQuestions.filter(
      (question) => question.sectionId !== targetLocation,
    );

    // Remove questions from their original locations
    filteredQuestions.forEach((filteredQuestion) => {
      if (filteredQuestion.sectionId) {
        const originalSectionIdx = itemsCopy.findIndex(
          (item: any) =>
            item.id === filteredQuestion.sectionId || item._itemId === filteredQuestion.sectionId,
        );
        if (originalSectionIdx !== -1) {
          const originalQuestionIdx = itemsCopy[originalSectionIdx].questions.findIndex(
            (q: any) => q.id === filteredQuestion.id,
          );
          if (originalQuestionIdx !== -1)
            itemsCopy[originalSectionIdx].questions.splice(originalQuestionIdx, 1);
        }
      } else {
        const originalQuestionIdx = itemsCopy.findIndex(
          (item: any) => item.id === filteredQuestion.id,
        );
        itemsCopy.splice(originalQuestionIdx, 1);
      }
    });

    // If target ID is present it means a target section was chosen
    if (questionTargetLocationId) {
      // Find the index of the target section in the items copy
      const targetSectionIdx = itemsCopy.findIndex(
        (item: any) => item.id === questionTargetLocationId,
      );

      if (targetSectionIdx !== -1) {
        const targetSectionCurQuestions = itemsCopy[targetSectionIdx].questions;
        const joinedQuestions = [...filteredQuestions, ...targetSectionCurQuestions];
        const updatedQuestions = joinedQuestions.map((q, idx) => ({
          ...q,
          sectionId: itemsCopy[targetSectionIdx].id,
          position: idx + 1,
        }));

        itemsCopy[targetSectionIdx].questions = updatedQuestions;
        newItems = itemsCopy;
        // setDndItems(itemsCopy);
      }
      // Else no target section was chosen, so the questions need to be moved to be independent
    } else {
      const joinedItems = [...filteredQuestions, ...itemsCopy];
      newItems = joinedItems.map((item, idx) => ({
        ...item,
        sectionId: null,
        position: idx + 1,
      }));
      // setDndItems(updatedItems);
    }

    setDndItems(newItems);
    setSelectedQuestions([]);

    methods.setValue(
      'independentQuestions',
      newItems
        .filter(
          (currentItem: Record<string, any>) => isQuestionOrSection(currentItem) === 'question',
        )
        .map((q: { [x: string]: any; _tempId?: any; id?: any }) => {
          if (q._tempId) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = q;
            return rest;
          }
          return q;
        }) as any,
    );

    methods.setValue(
      'sections',
      newItems
        .filter(
          (currentItem: Record<string, any>) => isQuestionOrSection(currentItem) === 'section',
        )
        .map((s: { [x: string]: any; _tempId?: any; id?: any }) => {
          if (s._tempId) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = s;
            return rest;
          }
          return s;
        }) as any,
    );
  };

  const renderAddQuestionToolbar = (
    <Stack direction="row" alignItems="center" justifyContent="center" my="3rem">
      <Button
        variant="contained"
        startIcon={<Iconify icon="mingcute:add-line" />}
        sx={{ minWidth: '142px', backgroundColor: 'secondary.main' }}
        onClick={handleAddNewQuestion}
        disabled={isEditing || selectedQuestions.length > 0}
      >
        New question
      </Button>
      <Typography
        variant="body2"
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          color: 'grey.500',
          minWidth: '70px',
          textAlign: 'center',
        }}
      >
        or
      </Typography>
      <Box sx={{ minWidth: '425px' }}>
        <QuestionSearchBox
          excludeIds={excludeIdsFromSearch}
          onQuestionChosen={handleSearchQuestionChoice}
          disabled={isEditing || selectedQuestions.length > 0}
        />
      </Box>
    </Stack>
  );

  return (
    <>
      <Stack sx={{ mt: '2rem' }}>
        {selectedQuestions.length > 0 && (
          <Stack direction="row" alignItems="center" sx={{ mt: '40px' }} spacing="24px">
            <Stack direction="row" alignItems="center" spacing="8px">
              <Iconify icon="ri:check-double-fill" color="grey.600" />
              <Typography
                variant="body2"
                sx={{ fontSize: '14px', fontWeight: '900', color: 'grey.600' }}
              >
                {selectedQuestions.length} elements selected
              </Typography>
            </Stack>
            <Button
              variant="contained"
              startIcon={<Iconify icon="simple-line-icons:frame" />}
              sx={{ backgroundColor: 'success.main' }}
              onClick={handleNewSectionDialogOpen}
            >
              Create a Section
            </Button>
            <Typography
              variant="body2"
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: 'grey.500',
                minWidth: '70px',
                textAlign: 'center',
              }}
            >
              or add to
            </Typography>
            <Stack direction="row" alignItems="center" spacing="8px">
              <TestSectionsDropdown
                sectionOpts={itemSections as ITestSection[]}
                selectedSectionId={questionTargetLocationId}
                setSelectedSectionId={setQuestionTargetLocationId}
                sx={{
                  minWidth: '320px',
                  maxHeight: '40px',
                }}
              />
              <Button
                variant="contained"
                sx={{ minWidth: '40px', backgroundColor: 'secondary.main' }}
                onClick={handleMoveQuestions}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        )}

        {independentQuestions.length > 0 || (sectionsArr && sectionsArr.length > 0) ? (
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Box sx={{ mt: '3rem' }}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                // onDragOver={handleDragOver}
                modifiers={[restrictToVerticalAxis]}
              >
                <Stack spacing="2rem">
                  <SortableContext items={dndItems as any} strategy={verticalListSortingStrategy}>
                    {dndItems.map(
                      (
                        item:
                          | {
                              id?: string | undefined;
                              _tempId?: string | undefined;
                              instruction: string;
                              testId: string;
                              position: number;
                              name: string;
                              questions: {
                                answers?: { text: string; points: number }[] | undefined;
                                totalPoints?: number | undefined;
                                instruction?: string | null | undefined;
                                imagePath?: string | null | undefined;
                                audioPath?: string | null | undefined;
                                videoPath?: string | null | undefined;
                                mimeType?: string | null | undefined;
                                id?: string | undefined;
                                sectionId?: string | null | undefined;
                                questionId?: string | null | undefined;
                                _tempId?: string | undefined;
                                question: string;
                                questionType: NonNullable<
                                  'text' | 'single' | 'multiple' | undefined
                                >;
                                tags: (string | undefined)[];
                                isPublished: boolean;
                                testId: string;
                                position: number;
                                isUpdatedOnQuestionsTable: boolean;
                                isNewQuestion?: boolean;
                              }[];
                            }
                          | Record<string, any>,
                      ) => {
                        if (isQuestionOrSection(item) === 'question') {
                          return isEditing &&
                            (item._tempId === editQuestionId || item.id === editQuestionId) ? (
                            <TestCreateEditQuestion
                              key={item._tempId || item.id}
                              currentQuestion={item as ITestQuestion}
                              handleCloseEditQuestion={handleCloseEditQuestion}
                              setDndItems={setDndItems}
                            />
                          ) : (
                            <TestQuestionsBox
                              key={item.id || item._tempId}
                              id={item.id || item._tempId!}
                              question={item as ITestQuestion}
                              handleEditQuestion={handleEditQuestion}
                              handleQuestionSelect={handleQuestionSelect}
                              selectedQuestions={selectedQuestions}
                              setDndItems={setDndItems}
                            />
                          );
                        }
                        if (isQuestionOrSection(item) === 'section') {
                          return (
                            <TestSectionBox
                              key={item.id || item._tempId}
                              id={item.id || item._tempId!}
                              section={item as SaveTestSectionsYupSchema}
                              handleEditQuestion={handleEditQuestion}
                              handleCloseEditQuestion={handleCloseEditQuestion}
                              handleQuestionSelect={handleQuestionSelect}
                              selectedQuestions={selectedQuestions}
                              editQuestionId={editQuestionId}
                              isEditing={isEditing}
                              setDndItems={setDndItems}
                            />
                          );
                        }
                        return null;
                      },
                    )}
                  </SortableContext>
                </Stack>
              </DndContext>
            </Box>
            {renderAddQuestionToolbar}
            <Divider sx={{ borderStyle: 'dashed', my: '2rem' }} />

            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <RHFSwitch
                  name="isRandomized"
                  label="Randomize questions"
                  sx={{ textWrap: 'nowrap' }}
                  disabled={isEditing}
                />
                <RHFTextField name="dimension" size="small" label="Dimensions" />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => {
                    reset(defaultValues);
                  }}
                  color="inherit"
                  variant="outlined"
                  disabled={isEditing}
                >
                  Cancel
                </Button>
                <LoadingButton
                  color="success"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isEditing}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        ) : (
          <>
            <NoQuestionsAdded />
            {renderAddQuestionToolbar}
          </>
        )}
      </Stack>
      <Dialog
        open={newSectionDialog.value}
        onClose={handleDialogClose}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '720px',
          },
        }}
      >
        <DialogTitle sx={{ color: 'info.dark', fontSize: '18px', fontWeight: '900' }}>
          New section
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '4px !important' }}>
          <TextField
            autoFocus
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <Button color="success" variant="contained" onClick={handleCreateSection}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TestEditQuestionsView;
