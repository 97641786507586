import v from 'voca';
import React from 'react';
import { useNavigate } from 'react-router';

import { Paper, Stack, ListItemText } from '@mui/material';

import { paths } from 'src/routes/paths';

import { roleTypeToPlural } from 'src/constants/template';

import Iconify from 'src/components/iconify/iconify';

import { ITemplateItem } from 'src/types/templates';

type Props = {
  template: ITemplateItem;
};

const ICON_TYPE: { [key: string]: string } = {
  student: 'mdi:account-box',
  collaborator: 'mdi:account-multiple',
};

export default function EmailTemplatesItems({ template }: Props) {
  const navigate = useNavigate();
  const renderIcon = (
    <Iconify icon={ICON_TYPE[template.role]} height={40} width={40} color="customColors.custom1" />
  );

  const renderText = (
    <ListItemText
      primary={v.titleCase(roleTypeToPlural[template.role] || template.role)}
      secondary={`${template.count} Email templates`}
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
        fontWeight: 900,
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'body2',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'unset', sm: 'center' }}
      height={{ md: 94 }}
      onClick={() => navigate(paths.staff.notifications.getEmailTemplates(template.role))}
      sx={{
        borderRadius: 0.5,
        bgcolor: 'unset',
        cursor: 'pointer',
        position: 'relative',
        p: { xs: 2.5, sm: 2 },
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20,
        },
      }}
    >
      {renderIcon}
      {renderText}
    </Stack>
  );
}
