export const FILE_TYPES = {
  XLSX: 'xlsx',
  CSV: 'csv',
  PDF: 'pdf',
  XLSX_LOGISTICS: 'logistic-xlsx',
};

export const ENVIRONMENTS = {
  dev: 'develop',
  stage: 'stage',
  prod: 'production',
};

export const TABLE_DEFAULTS = {
  CURRENT_PAGE: 0,
  ROWS_PER_PAGE: 20,
  TOTAL_PAGES: 1,
};

// export const FILE_UPLOAD_PATHS = {
//   questions: 'question',
//   producer: 'producer',
// };

// export const MIME_TYPES = {
//   jpg: 'image/jpg',
// };

export const CLONE_QUERY = {
  clone_order_code: 'clone_order_code',
  clone_product_sku: 'clone_product_sku',
  clone_producer_slug: 'clone_producer_slug',
  clone_client: 'clone_client',
  clone_user: 'clone_user',
};

export const STAT_ROWS_PER_PAGE_OPTIONS = [25, 50, 75, 100];
export const DEFAULT_STAT_ROWS_PER_PAGE = 25;

export const MIME_TYPES = {
  jpg: 'image/jpg',
};

export const FILE_UPLOAD_PATHS = {
  image: 'image',
};
