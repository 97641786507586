import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import { GuestGuard, NonStaffGuard } from 'src/auth/guard';

import { SplashScreen } from 'src/components/loading-screen';

import { paths } from '../paths';
// ----------------------------------------------------------------------

const RegisterPage = lazy(() => import('src/pages/auth/firebase/register'));
const FirebaseLoginPage = lazy(() => import('src/pages/auth/firebase/login'));
const HomePage = lazy(() => import('src/pages/public/home'));
const AnswerTestPage = lazy(() => import('src/pages/public/test/answer-test'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    path: paths.root,
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        element: (
          <NonStaffGuard>
            <HomePage />
          </NonStaffGuard>
        ),
        index: true,
      },
      {
        path: paths.login,
        element: (
          <GuestGuard>
            <FirebaseLoginPage />
          </GuestGuard>
        ),
      },
      {
        path: paths.signup,
        element: (
          <GuestGuard>
            <RegisterPage />
          </GuestGuard>
        ),
      },
      {
        path: paths.test.answerTest(':id'),
        element: <AnswerTestPage />,
      },
      { path: paths.courses, element: <h1>Courses page</h1> },
      { path: paths.premises, element: <h1>Premises page</h1> },
      { path: paths.aboutUs, element: <h1>About us page</h1> },
      { path: paths.contactUs, element: <h1>Contact us page</h1> },
      { path: paths.jobs, element: <h1>Jobs page</h1> },
      { path: paths.franchising, element: <h1>Franchising page</h1> },
      { path: paths.privacyPolicy, element: <h1>Privacy policy page</h1> },
      { path: paths.cookiePolicy, element: <h1>Cookie policy page</h1> },
      { path: paths.cookieSettings, element: <h1>Cookie settings page</h1> },
    ],
  },
];
