import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, useFieldArray } from 'react-hook-form';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Button, Divider, IconButton, Typography } from '@mui/material';

import { useDebounce } from 'src/hooks/use-debounce';

import keys from 'src/constants/query-keys';
import { updateTestInCategory, useGetCategoryTestList } from 'src/api/categories';

import Iconify from 'src/components/iconify/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';

import { ICategoryItem } from 'src/types/category';

import TestSearch from './categoryies-test-search';

export default function CategoryEditViewTests({
  tests,
  id,
}: Required<Pick<ICategoryItem, 'tests' | 'id'>>) {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [excludeIds, setExcludeIds] = useState<string[]>([]);

  const [testSearchText, setTestSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(testSearchText, 1500);

  const { data: testToAdd = [], isPending } = useGetCategoryTestList({
    search: debouncedSearchText,
    excludeIds,
  });

  const EditCategoryTestsSchema = Yup.object().shape({
    primaryTestId: Yup.object({
      name: Yup.string().required(),
      id: Yup.string().required(),
    }).test('is primary test have value', 'primary test cannot be empty', (val) => {
      if (!val.id || !val.name) return false;
      return true;
    }),
    otherTestsData: Yup.array().of(
      Yup.object({
        checked: Yup.boolean().default(true),
        unlocksAt: Yup.number().min(1, '% > 0').max(100, '% < 101').required(),
        name: Yup.string().required(),
        id: Yup.string().required(),
        totalResults: Yup.number().notRequired(),
        totalQuestions: Yup.number().notRequired(),
      }).test('is test have value', 'test cannot be empty', (val) => {
        if (!val.id || !val.name) return false;
        return true;
      }),
    ),
  });

  const NewCategoryTestSchema = Yup.object().shape({
    checked: Yup.boolean(),
    unlocksAt: Yup.number().when('checked', {
      is: true,
      then: (schema) =>
        schema.min(1, '% > 0').max(100, '% < 101').required('value must be a number'),
    }),
    test: Yup.object({
      name: Yup.string(),
      id: Yup.string(),
    }).test('is test have value', 'test cannot be empty', (val) => {
      if (checkedNewWatcher && (!val.id || !val.name)) return false;
      return true;
    }),
  });

  const allTests = [...tests];
  const primaryTest = allTests.pop();

  const defaultValues = useMemo(
    () => ({
      primaryTestId: primaryTest!,
      otherTestsData: allTests.map((tes) => ({ checked: true, ...tes })),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tests],
  );

  const newTestDefaultValues = {
    checked: false,
    unlocksAt: 0,
    test: {
      name: '',
      id: '',
    },
  };

  const methods = useForm({
    resolver: yupResolver(EditCategoryTestsSchema),
    defaultValues,
  });

  const newTestMethod = useForm({
    resolver: yupResolver(NewCategoryTestSchema),
    defaultValues: newTestDefaultValues,
  });

  const {
    handleSubmit: newTestHandleSubmit,
    watch: newTestWatch,
    reset: newTestReset,
  } = newTestMethod;
  const checkedNewWatcher = newTestWatch('checked');

  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, isDirty },
  } = methods;

  useEffect(() => {
    if (tests) {
      reset(defaultValues);
    }
  }, [tests, defaultValues, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'otherTestsData',
  });

  useEffect(() => {
    const subscription = watch((data) => {
      const allCurrTestIds = [
        ...(data.otherTestsData
          ?.map((field) => field && field.id)
          .filter((field): field is string => typeof field === 'string') ?? []),
        data.primaryTestId?.id,
      ].filter((field): field is string => typeof field === 'string');

      // const currIncludedIds = tests
      //   .map((testObj) => testObj.id)
      //   .filter((field) => !currExcludeIds.includes(field));

      setExcludeIds(allCurrTestIds);
    });

    return () => subscription.unsubscribe();
  }, [watch, tests]);

  const onSubmitNew = newTestHandleSubmit(async (data: any) => {
    append({ checked: true, unlocksAt: data.unlocksAt, ...data.test });
    newTestReset();
    queryClient.invalidateQueries({
      queryKey: [keys.staff.categories.fetchCategory, keys.staff.categories.fetchTestForCategories],
    });
  });

  const onSubmit = handleSubmit(async (categoryTests) => {
    const transformedData = {
      primaryTestId: categoryTests?.primaryTestId?.id || primaryTest?.id,
      otherTestsData: (categoryTests?.otherTestsData || [])
        .filter((test) => test.checked === true)
        .map(({ id: testId, unlocksAt }) => ({ testId, unlocksAt })),
    };
    try {
      await updateTestInCategory(id, transformedData);
      queryClient.invalidateQueries({ queryKey: [keys.staff.categories.fetchCategory] });
    } catch (error) {
      const errMsg = error?.response?.data?.message || error?.message;
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  });

  const handleSearch = useCallback((inputValue: string) => {
    setTestSearchText(inputValue);
  }, []);

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack
          spacing={2}
          marginY={5}
          sx={{ p: 3, bgcolor: 'background.neutral' }}
          borderRadius={2}
        >
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Iconify icon="solar:eye-bold" />
            <Typography variant="subtitle1" fontWeight="bold">
              Primary Test
            </Typography>
          </Stack>
          <TestSearchComponent excludeIds={excludeIds} name="primaryTestId" />
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Typography variant="body2">
            {primaryTest?.totalResults} results, {primaryTest?.totalQuestions} questions
          </Typography>
        </Stack>
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Divider sx={{ borderStyle: 'dashed', marginY: 5 }} />
            <Stack flexDirection="row" alignItems="center" marginTop={3} marginBottom={2}>
              <RHFCheckbox
                label="if test result is"
                name={`otherTestsData[${index}].checked`}
                color="primary"
              />
              <Typography variant="h6" marginRight={1}>
                &gt;
              </Typography>
              <RHFTextField
                size="small"
                sx={{
                  width: '75px',
                  '& .MuiFormHelperText-root': {
                    position: 'absolute',
                    bottom: '-1.5rem',
                  },
                }}
                type="number"
                name={`otherTestsData[${index}].unlocksAt`}
                label="%"
                InputProps={{
                  endAdornment: '%',
                }}
              />
              <Typography variant="body2" marginLeft={1}>
                go to
              </Typography>
            </Stack>
            <Stack display="flex" flexDirection="row" alignItems="center" gap={2}>
              <Iconify icon="solar:forward-2-bold" color="blue" />
              <Stack spacing={2} sx={{ p: 3, width: 1 }} boxShadow={2} borderRadius={2}>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <Iconify icon="solar:eye-closed-bold" />
                  <TestSearchComponent excludeIds={excludeIds} name={`otherTestsData[${index}]`} />
                </Stack>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2">
                    {field.totalResults} results, {field.totalQuestions} questions
                  </Typography>
                  <Box>
                    <IconButton disabled>
                      <Iconify icon="eva:external-link-outline" />
                    </IconButton>
                    <IconButton>
                      <Iconify icon="solar:share-bold" />
                    </IconButton>
                    <Button
                      variant="soft"
                      color="error"
                      size="small"
                      onClick={() => {
                        remove(index);
                        const indexOfExludedIds = excludeIds.indexOf(field.id);

                        if (indexOfExludedIds !== -1) {
                          excludeIds.splice(indexOfExludedIds, 1);
                        }
                      }}
                      endIcon={<Iconify icon="mingcute:close-line" />}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </React.Fragment>
        ))}
        <Divider sx={{ borderStyle: 'dashed', marginY: 5 }} />
        <Grid sx={{ float: 'right' }}>
          <Button
            onClick={() => {
              reset();
            }}
            size="large"
            color="inherit"
            variant="outlined"
            sx={{ alignSelf: 'center', marginRight: 2 }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            disabled={!isDirty}
            loading={isSubmitting}
          >
            Update
          </LoadingButton>
        </Grid>
      </FormProvider>
      <FormProvider methods={newTestMethod} onSubmit={onSubmitNew}>
        <Stack gap={2} flexDirection="row" flexWrap="wrap" alignItems="center">
          <Stack gap={2} flexDirection="row" alignItems="center">
            <RHFCheckbox name="checked" label="if test result is" color="primary" />
            <Typography variant="h6">&gt;</Typography>
            {/* <FormControl sx={{ width: '75px' }} size="small" variant="outlined"> */}
            <RHFTextField
              size="small"
              sx={{
                width: '75px',
                '& .MuiFormHelperText-root': {
                  position: 'absolute',
                  bottom: '-1.5rem',
                },
              }}
              type="number"
              name="unlocksAt"
              label="%"
              InputProps={{
                endAdornment: '%',
              }}
              disabled={!checkedNewWatcher}
            />
          </Stack>
          <Stack gap={2} flexDirection="row" alignItems="center">
            <Typography variant="body2">go to</Typography>
            <TestSearch
              disabled={!checkedNewWatcher}
              name="test"
              query={debouncedSearchText}
              results={testToAdd as any}
              searchInput={testSearchText}
              onSearch={handleSearch}
              loading={isPending}
              isForAppendNewTest
            />
            <Button
              variant="contained"
              color="success"
              type="submit"
              endIcon={<Iconify icon="mingcute:add-line" />}
              disabled={!checkedNewWatcher}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  );
}

function TestSearchComponent({ name, excludeIds }: { name: string; excludeIds: string[] }) {
  const [testSearchText, setTestSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(testSearchText, 1500);

  const {
    data: testss = [],
    // isError,
    isPending,
  } = useGetCategoryTestList({
    search: debouncedSearchText,
    excludeIds,
  });

  const handleSearch = useCallback((inputValue: string) => {
    setTestSearchText(inputValue);
  }, []);

  return (
    <TestSearch
      name={name}
      disableClearable
      query={debouncedSearchText}
      results={testss as any}
      searchInput={testSearchText}
      onSearch={handleSearch}
      loading={isPending}
    />
  );
}
