import React from 'react';

import { Container } from '@mui/material';

import { useGetSettings } from 'src/api/templates';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import { ISettingDetailItem } from 'src/types/templates';

import SettingEditForm from '../edit/settings-edit-form';

export default function SettingsEditView() {
  const settings = useSettingsContext();

  const { data }: any = useGetSettings();
  const settingDetails: ISettingDetailItem = React.useMemo(() => data || {}, [data]);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Settings"
        links={[
          { name: 'Dashboard', href: '#' },
          { name: 'Notification', href: '#' },
          { name: 'Settings' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <SettingEditForm settingDetails={settingDetails} />
    </Container>
  );
}
