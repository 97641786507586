import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useCallback } from 'react';

import { Table, TableBody, Container, TableContainer } from '@mui/material';

import { useDebounce } from 'src/hooks/use-debounce';

import { csvDownloader } from 'src/utils/file-downloader';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { removeFalsyValuesFromObject } from 'src/utils/misc';

import { TABLE_DEFAULTS } from 'src/constants/misc';
import { useGetStudentsList } from 'src/api/students';

import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { IListFilters } from 'src/types/misc';
import {
  IPremiseItem,
  IStudentsItem,
  IStudentModifiedItem,
  IStudentsTableFilters,
  IStudentsTableFilterValue,
} from 'src/types/students';

import StudentsToolbar from '../list/students-toolbar';
import StudentTableRow from '../list/students-table-row';
import StudentsTableFiltersResult from '../list/students-table-filters-result';

const defaultFilters = {
  searchText: '',
};

const EXCEL_DOWNLOAD_HEADERS = ['Name', 'Email', 'Phone', 'Results', 'Level', 'Premise'];

const EXCEL_DATA_KEYS = ['fullName', 'email', 'phone', 'totalResults', 'level', 'premises'];

const TABLE_HEAD = [
  { id: 'fullName', label: 'Name', width: 205, align: 'left' },
  { id: 'email', label: 'Email', width: 243, align: 'left' },
  { id: 'phone', label: 'Phone', width: 116, align: 'left' },
  { id: 'totalResults', label: 'Results', width: 83, align: 'left' },
  { id: 'level', label: 'Level', width: 131, align: 'left' },
  { id: 'premises', label: 'Premise', width: 191, align: 'left' },
  { id: 'actions', label: 'Actions', align: 'left' },
  { id: '' },
];

export default function StudentListView() {
  const table = useTable({ defaultRowsPerPage: TABLE_DEFAULTS.ROWS_PER_PAGE });

  const settings = useSettingsContext();

  const [filters, setFilters] = useState(defaultFilters);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const canReset = !isEqual(defaultFilters, filters);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const handleFilters = useCallback(
    (name: string, value: IStudentsTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) => ({
      ...(fetchAll
        ? { all: true }
        : {
            page: table.page + 1,
            pageSize: table.rowsPerPage,
          }),
      sortBy: table.orderBy === 'fullName' ? 'name' : table.orderBy,
      sortDirection: table.order,
    }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<IStudentsTableFilters> = { ...filters };
    if (allFilters.searchText) {
      delete allFilters.searchText;
    }

    return allFilters;
  }, [filters]);

  const [debouncedSearchText] = useDebounce(filters.searchText || '', 1500);

  const apiFilters: IListFilters<IStudentsTableFilters> = useMemo(() => {
    const allFilters = apiFiltersHandler();
    const tableFilters = tableFiltersHandler({ fetchAll: false });

    return removeFalsyValuesFromObject({
      ...allFilters,
      ...tableFilters,
      search: debouncedSearchText,
    });
  }, [apiFiltersHandler, tableFiltersHandler, debouncedSearchText]);

  const { data }: any = useGetStudentsList({ ...apiFilters });

  const {
    students: tableData = [],
    totalCount = 0,
  }: {
    students: IStudentModifiedItem[];
    totalCount: number;
  } = useMemo(() => {
    const modifyData = data?.data.map((student: IStudentsItem) => ({
      ...student,
      premises: student.premises.map((pr: IPremiseItem) => pr.name),
    }));
    return {
      students: modifyData,
      totalCount:
        data && data?.total_pages > 1
          ? data.total_pages * table.rowsPerPage
          : data?.data.length || 0,
    };
  }, [data, table]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const fileDownloader = useCallback(
    ({ fetchedRows, fileType }: { fetchedRows: any[]; fileType: string }) => {
      const rows = fetchedRows.map((rowData) =>
        EXCEL_DATA_KEYS.map((key) => rowData[key as keyof {}] as string | number | boolean),
      );
      csvDownloader({
        fileName: 'Premises-list',
        fileType,
        headers: EXCEL_DOWNLOAD_HEADERS as string[],
        rows: rows as (string | number)[][],
      });
    },
    [],
  );

  const excelDataModifier = useCallback(
    (rows: IStudentsItem[]) =>
      rows?.map((row) => ({
        ...row,
        premises: row.premises.map((pr: IPremiseItem) => pr.name),
      })) || [],
    [],
  );

  const downloadStudents = useCallback(
    async (fileType: string) => {
      try {
        setIsFileLoading(true);
        const {
          data: { data: downloadData },
        } = await axiosInstance(endpoints.staff.students, {
          params: canReset
            ? removeFalsyValuesFromObject({
                ...apiFiltersHandler(),
                ...tableFiltersHandler({ fetchAll: true }),
                search: debouncedSearchText,
              })
            : { sendAllRows: true },
        });
        if (downloadData?.data.length > 0) {
          fileDownloader({ fetchedRows: excelDataModifier(downloadData?.data || []), fileType });
        } else {
          enqueueSnackbar('no_data_found', { variant: 'error' });
        }
        setIsFileLoading(false);
      } catch (error) {
        setIsFileLoading(false);
        enqueueSnackbar(JSON.stringify(error || '{}'), { variant: 'error' });
      }
    },
    [
      apiFiltersHandler,
      canReset,
      debouncedSearchText,
      excelDataModifier,
      fileDownloader,
      tableFiltersHandler,
    ],
  );

  const fileDownloadHandler = useCallback(() => downloadStudents('csv'), [downloadStudents]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Students"
        links={[{ name: 'Dashboard', href: '#' }, { name: 'students' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <StudentsToolbar
        onFilters={handleFilters}
        filters={filters}
        fileDownloadHandler={fileDownloadHandler}
        isFileLoading={isFileLoading}
      />

      {canReset && (
        <StudentsTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={totalCount}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              // TODO:
              rowCount={20}
              numSelected={table.selected.length}
              onSort={table.onSort}
            />

            <TableBody>
              {tableData.length > 0 &&
                tableData.map((row, index) => (
                  <StudentTableRow key={`${row.id} ${index}`} row={row} headLabel={TABLE_HEAD} />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalCount}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Container>
  );
}
