import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

// import { bgBlur } from 'src/theme/css';

import { useAuthContext } from 'src/auth/hooks/use-auth-context';

import Logo from 'src/components/logo';
import CustomButton from 'src/components/custom-button/custom-button';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import LoginButton from '../common/login-button';
import HeaderShadow from '../common/header-shadow';
import LogoutButton from '../common/logout-button';
import ProfileButton from '../common/profile-button';
// import AdminAreaButton from '../common/admin-area-button';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { authenticated } = useAuthContext();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar color="primary">
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          // ...(offsetTop && {
          //   ...bgBlur({
          //     color: theme.palette.background.default,
          //   }),
          //   height: {
          //     md: HEADER.H_DESKTOP_OFFSET,
          //   },
          // }),
        }}
      >
        <Container
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
            m: 0,
            '&.MuiContainer-root': {
              maxWidth: '100%',
              paddingX: '32px',
            },
          }}
        >
          <Box sx={{ flexShrink: '1' }}>
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack alignItems="end" direction="column" spacing={3}>
            <Stack direction="row" spacing={5}>
              {authenticated && <ProfileButton />}
              {authenticated ? <LogoutButton /> : <LoginButton />}
            </Stack>
            <Stack alignItems="center" direction="row" spacing={2}>
              {mdUp && <NavDesktop data={navConfig} />}
              <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }} spacing={2}>
                <CustomButton customvariant="contact" href={paths.contactUs}>
                  CONTACT US
                </CustomButton>
                {!mdUp && <NavMobile data={navConfig} />}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
