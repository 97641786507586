import { Children, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import {
  Box,
  Chip,
  Stack,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import axiosInstance, { endpoints } from 'src/utils/axios';

import queryKeys from 'src/constants/query-keys';
import { deleteStaff } from 'src/api/collaborators';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { ITableColumns } from 'src/types/misc';
import { roleColors, ICollaboratorsItem } from 'src/types/collaborators';

import InviteEditCollaboratorsDialog from '../list/invite-edit-collaborators-dialog';

type Props = {
  row: ICollaboratorsItem;
  headLabel: Partial<ITableColumns>[] | any;
};

export default function CollaboratorsTableRow({ row, headLabel }: Props) {
  const [editData, setEditData] = useState<ICollaboratorsItem | null>(null);
  const confirm = useBoolean();
  const queryClient = useQueryClient();
  const editCollaboratorsDialog = useBoolean();

  const handleDeleteStaff = async () => {
    try {
      confirm.onFalse();
      await deleteStaff(row.id);
      enqueueSnackbar(`Staff Deleted`);
      queryClient.invalidateQueries({ queryKey: [queryKeys.staff.staffs.fetchAllStaffs] });
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    }
  };

  const handleEdit = async () => {
    try {
      const URL = `${endpoints.staff.staffs}/${row.id}`;
      const { data }: { data: { data: ICollaboratorsItem } } = await axiosInstance.get(URL);

      setEditData(data.data);
      editCollaboratorsDialog.onTrue();
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const tableActions = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title="Edit collaborator" placement="top" arrow>
        <IconButton onClick={handleEdit}>
          <Iconify icon="solar:pen-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

  const tableDeleteAction = (
    <TableCell align="center" sx={{ px: 2, whiteSpace: 'nowrap' }} width={77}>
      <Tooltip title="Delete collaborators" placement="top" arrow>
        <IconButton onClick={() => confirm.onTrue()} sx={{ color: 'error.dark' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
  return (
    <>
      <TableRow hover>
        {Children.toArray(
          headLabel.map((headCell: any) => {
            if (headCell?.field_mod) {
              return (
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                  }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.field_mod]}
                </TableCell>
              );
            }
            if (headCell?.id) {
              if (headCell.id === 'email') {
                return (
                  <TableCell
                    sx={{
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                      whiteSpace: 'nowrap',
                      textDecoration: 'none',
                      color: 'customColors.custom1',
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* <Link> */}
                    {/* @ts-ignore */}
                    {row?.[headCell?.id]}
                    {/* </Link> */}
                  </TableCell>
                );
              }

              if (headCell.id === 'actions') {
                return <>{tableActions}</>;
              }

              if (headCell.id === 'role') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    <Chip
                      /* @ts-ignore */
                      label={row?.[headCell?.id]}
                      sx={{
                        /* @ts-ignore */
                        backgroundColor: roleColors[row?.[headCell?.id]],
                        '&.MuiChip-root': {
                          height: 1,
                        },
                      }}
                    />
                  </TableCell>
                );
              }

              if (headCell.id === 'premises') {
                return (
                  <TableCell
                    sx={{
                      whiteSpace: 'nowrap',
                      width: headCell.width,
                      minWidth: headCell.minWidth,
                    }}
                    align={headCell.align || 'left'}
                  >
                    {/* @ts-ignore */}
                    {row?.[headCell?.id].length ? (
                      <Stack direction="row" spacing={2}>
                        <Iconify color="secondary.lighter" icon="fluent:location-24-filled" />
                        {/* @ts-ignore */}
                        {row?.[headCell?.id].map((pr) => pr.premise.name).join(', ')}
                      </Stack>
                    ) : (
                      ''
                    )}
                  </TableCell>
                );
              }

              return (
                <TableCell
                  sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                  align={headCell.align || 'left'}
                >
                  {/* @ts-ignore */}
                  {row?.[headCell?.id]}
                </TableCell>
              );
            }
            return <>{tableDeleteAction}</>;
          }),
        )}
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Iconify icon="solar:trash-bin-trash-bold" width={24} height={24} />{' '}
            <Typography variant="h6" fontWeight={900}>
              Delete a collaborators?
            </Typography>
          </Box>
        }
        content={
          <Typography variant="subtitle1" fontWeight={400}>
            Are you sure you want to delete this collaborator account?
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={handleDeleteStaff}>
            Delete
          </Button>
        }
      />

      {editData && (
        <InviteEditCollaboratorsDialog
          dialog={editCollaboratorsDialog}
          collaborator={editData}
          setEditData={setEditData}
        />
      )}
    </>
  );
}
